import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard';
import '../../assets/css/admin/allcourses.css';

const AllBankStdLoans = () => {
    const [bankStdLoan, setbankStdLoan] = useState([]);

    useEffect(() => {
        fetchbankStdLoan();
    }, []);

    const fetchbankStdLoan = async () => {
        try {
            const response = await fetch('https://skillbackend.educationesupport.xyz/bankStdLoan/all');
            const data = await response.json();
            setbankStdLoan(data);
        } catch (error) {
            console.error('Error fetching Student Loans:', error);
        }
    };

    const handleDelete = async (bankStdLoanId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/bankStdLoan/delete/${bankStdLoanId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setbankStdLoan(bankStdLoan.filter(bankStdLoan => bankStdLoan.id !== bankStdLoanId));
            } else {
                console.error('Failed to delete Student Loan');
            }
        } catch (error) {
            console.error('Error deleting Student Loan:', error);
        }
    };

    return (
        <div>
            <AdminDashboard />

            <div className='AllViews-admin-course'>
                <div className="search-icon-admin admin-course-search">
                    <h1>ALL BANK STUDENT LOANS</h1>
                    <form>
                        <input type="text" name="" id="" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                </div>

                <div className="scroller-box">
                    <div className="table-course">
                    <table> 
                            <thead>
                                <tr>
                                <th>Bank Name</th>
                                    <th>Student Loan Name </th>
                                    {/* <th>University Name</th> */}
                                    <th>Contact No.</th>
                                    <th>Email</th>
                                    <th>Location</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {bankStdLoan.map((bankStdLoan) => (
                                    <tr key={bankStdLoan.id}>
                                        <td>{bankStdLoan.bankName}</td>
                                        <td>{bankStdLoan.stdLoanName}</td>
                                        {/* <td>{bankStdLoan.university}</td> */}
                                        <td>{bankStdLoan.phone}</td>
                                        <td>{bankStdLoan.email}</td>
                                        <td>{bankStdLoan.location}</td>
                                        <td>
                                            <a href="#!" id='view-website'>WEBSITE</a>
                                        </td>
                                        <td id='action-buttons'>
                                            {/* <a href="#!" id='man-details' onClick={() => handleEdit(course.id)}>EDIT</a> */}
                                            <a href="#!" id='del-details' onClick={() => handleDelete(bankStdLoan.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllBankStdLoans;
