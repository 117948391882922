import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/st_register.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer'; 
import Swal from 'sweetalert2';

const StdRegister = () => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    district: '',
    province: '',
    phone: '',
    mobile: '',
    email: '',
    school: '',
    course: '',
    category: '',
    message: '',
  });

  const [categories, setCategories] = useState([]);
  const navigate = useNavigate(); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    })); 
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://skillbackend.educationesupport.xyz/std/stdregister', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status === 400) { 
          return response.text().then((message) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Oops...',
              text: message,
              showConfirmButton: true,
            });
          });
        } else if (response.ok) {
          return response.text().then((data) => {
            console.log(data);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Successfully registered',
              text: 'You have been registered successfully.',
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              navigate('/'); 
            });
          });
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: true,
        });
      });
  };

  
  const fetchCategories = async () => {
    try {
        const response = await fetch('https://skillbackend.educationesupport.xyz/category/all');
        const data = await response.json();
        setCategories(data);
    } catch (error) {
        console.error('Error fetching Categories:', error);
    }
};

  fetchCategories();


  return (
    <main>
      <SecondNav />
      <div className="head-content-text">
        <h4>Student Register</h4>
      </div>
      <div className="r_container">
        <form onSubmit={handleSubmit}>
          <div className="new">
          <div className="formgroup">
            <i className="fas fa-layer-group"></i>
            <select
              id="course"
              name="course"
              value={formData.course}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                What you need?
              </option>
              <option value="Find a Course">Find a Course</option>
              <option value="Find a Degree">Find a Degree</option>
              <option value="Study Abroad">Study Abroad</option>
              <option value="Student Loan">Student Loan</option>
              <option value="NVQ Certificate">NVQ Certificate</option>
              <option value="Local Job">Local Job</option>
              <option value="Foreign Job">Foreign Job</option>
              <option value="External Degree">External Degree</option>
              <option value="Professional Exam">Professional Exam</option>
            </select>
          </div>

            <div className="formgroup">
              <i className="fas fa-user"></i>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Full Name"
                required
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Address"
                required
              />
              <div className="add-half">
                <select
                  id="district"
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select District
                  </option>
                  <option value="Ampara">Ampara</option>
                  <option value="Anuradhapura">Anuradhapura</option>
                  <option value="Badulla">Badulla</option>
                  <option value="Batticaloa">Batticaloa</option>
                  <option value="Colombo">Colombo</option>
                  <option value="Galle">Galle</option>
                  <option value="Gampaha">Gampaha</option>
                  <option value="Hambantota">Hambantota</option>
                  <option value="Jaffna">Jaffna</option>
                  <option value="Kalutara">Kalutara</option>
                  <option value="Kandy">Kandy</option>
                  <option value="Kegalle">Kegalle</option>
                  <option value="Kilinochchi">Kilinochchi</option>
                  <option value="Kurunegala">Kurunegala</option>
                  <option value="Mannar">Mannar</option>
                  <option value="Matale">Matale</option>
                  <option value="Matara">Matara</option>
                  <option value="Monaragala">Monaragala</option>
                  <option value="Mullaitivu">Mullaitivu</option>
                  <option value="NuwaraEliya">Nuwara Eliya</option>
                  <option value="Polonnaruwa">Polonnaruwa</option>
                  <option value="Puttalam">Puttalam</option>
                  <option value="Ratnapura">Ratnapura</option>
                  <option value="Trincomalee">Trincomalee</option>
                  <option value="Vavuniya">Vavuniya</option>
                </select>

                <select
                  id="province"
                  name="province"
                  value={formData.province}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Province
                  </option>
                  <option value="Northern">Northern</option>
                  <option value="North Western">North Western</option>
                  <option value="Western">Western</option>
                  <option value="North Central">North Central</option>
                  <option value="Central">Central</option>
                  <option value="Sabaragamuwa">Sabaragamuwa</option>
                  <option value="Eastern">Eastern</option>
                  <option value="Uva">Uva</option>
                  <option value="Southern">Southern</option>
                </select>
              </div>
            </div>

            <div className="formgroup">
              <i className="fas fa-phone"></i>
              <div className="half">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone"
                  required
                />
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="Mobile"
                  required
                />
              </div>
            </div>

            <div className="formgroup">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email Address"
                required
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-school"></i>
              <input
                type="text"
                id="school"
                name="school"
                value={formData.school}
                onChange={handleChange}
                placeholder="School Name"
                required
              />
            </div>

            {/* <div className="formgroup">
              <i className="fas fa-list-alt"></i>
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select Course Category
                </option>
                <option value="Business">Business</option>
                <option value="Engineering">Engineering</option>
                <option value="Science">Science</option>
                <option value="Arts">Arts</option>
                <option value="Technology">Technology</option>
              </select>
            </div>  */}

            <div className="formgroup">
            <i className="fas fa-list-alt"></i>
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                placeholder="Category"
              >
                <option value="" disabled>Select Course Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.category}>
                    {category.category}
                  </option>
                ))}
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-layer-group"></i>
              <select
                id="course"
                name="course"
                value={formData.course}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select Course Level
                </option>
                <option value="Training">Training</option>
                <option value="Certificate">Certificate</option>
                <option value="Diploma">Diploma</option>
                <option value="Advanced Diploma">Advanced Diploma</option>
                <option value="HND">HND</option>
                <option value="Degree">Degree</option>
                <option value="Postgraduate Diploma">Postgraduate Diploma</option>
                <option value="Master's">Master's</option>
                <option value="PhD">PhD</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-comment-dots"></i>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Your message"
              />
            </div>
          </div>
          <button type="submit">REGISTER</button>
        </form>
      </div>
      <Footer />
    </main>
  );
};

export default StdRegister;
