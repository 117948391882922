import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard'
import '../../assets/css/admin/allcourses.css'

const DeleteExternalDegrees = () => {
    const [externalDegrees, setExternalDegrees] = useState([]);

    useEffect(() => {
        fetchExternalDegrees();
    }, []);


    const fetchExternalDegrees = async () => {
        try {
            const response = await fetch('https://skillbackend.educationesupport.xyz/externalDegree/deleted');
            const data = await response.json();
            setExternalDegrees(data);
        } catch (error) {
            console.error('Error fetching deleted external degrees:', error);
        }
    };

    const handleRestore = async (externalDegreeId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/externalDegree/restore/${externalDegreeId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setExternalDegrees(externalDegrees.filter(externalDegree => externalDegree.id !== externalDegreeId));
                window.alert('Course Restored');
            } else {
                console.error('Failed to restore external degree');
            }
        } catch (error) {
            console.error('Error restoring external degree:', error);
        }
    };

    const handlePermanentDelete = async (externalDegreeId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/externalDegree/delete/permanent/${externalDegreeId}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setExternalDegrees(externalDegrees.filter(externalDegree => externalDegree.id !== externalDegreeId));
                window.alert('Course delete permanently');
            } else {
                console.error('Failed to delete external degree permanently');
            }
        } catch (error) {
            console.error('Error deleting external degree permanently:', error);
        }
    };

    return (

        <div>

            <AdminDashboard />

            <div className='AllViews-admin-course'>

                <div className="search-icon-admin admin-course-search">

                    <h1>DELETE EXTERNAL DEGREES</h1>
                    <form >
                        <input type="text" name="" id="" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                   
                </div>

                <div className="scroller-box">
                    <div className="table-course">
                    <table>
                            <thead>
                            <tr>
                                    <th>External Degree Name</th>
                                    <th>Institute Name</th>
                                    <th>Start Date</th>
                                    <th>Duration</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {externalDegrees.map((externalDegree) => (
                                    <tr key={externalDegree.id}>
                                        <td>{externalDegree.courseName}</td>
                                        <td>{externalDegree.university}</td>
                                        <td>{new Date(externalDegree.startDate).toLocaleDateString('en-CA')}</td>
                                        <td>{externalDegree.duration}</td>
                                        <td><a href="#!" id='view-website'>WEBSITE</a></td>
                                        <td id='action-buttons'>
                                            <a href="#!" id='restore-details' onClick={() => handleRestore(externalDegree.id)}>RESTORE</a>
                                            <a href="#!" id='del-details' onClick={() => handlePermanentDelete(externalDegree.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>



        </div>

    )

}

export default DeleteExternalDegrees