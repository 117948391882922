import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/courses.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';
import axios from 'axios';

const ProfessionalExams = () => {
  const [exams, setExams] = useState([]);
  const [search, setSearch] = useState(''); 
  const [filteredExams, setFilteredExams] = useState([]);
  const [displayedExamsCount, setDisplayedExamsCount] = useState(4);
  const navigate = useNavigate();

  const fetchExams = async () => {
    try {
      const response = await fetch(`https://skillbackend.educationesupport.xyz/professionalExams/all`);
      if (response.ok) {
        const data = await response.json();
        setExams(data);
        setFilteredExams(data); // Initialize filtered exams
      } else {
        console.error('Error fetching professional exams:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching professional exams:', error);
    }
  };

  useEffect(() => {
    fetchExams();
  }, []);

  useEffect(() => {
    if (search.trim() === '') {
      setFilteredExams(exams);
    } else {
      setFilteredExams(
        exams.filter(exam => 
          exam.examName &&
          typeof exam.examName === 'string' && 
          exam.examName.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, exams]);

  const handleExamClick = (id) => {
    navigate(`/professionalexamview/${id}`);
  };

  const handleSeeAllExams = () => {
    setDisplayedExamsCount((prevCount) => prevCount + 4);
  };

  const examsToShow = filteredExams.slice(0, displayedExamsCount);

  // Function to handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <SecondNav />
      <div className="Container">
        <div className="left-column">
            <div className="containerBoxOne-course">
                    <div className="content-warp">
                        <h4>FIND YOUR EXAM</h4>
                        <form id='formInstitutes' onSubmit={handleSubmit}>
                            <input 
                                type="text" 
                                placeholder="Enter Course Name" 
                                onChange={(e) => setSearch(e.target.value)} 
                            />
                            <input type="submit" value="Search" />
                        </form>
                    </div>
                </div>
        
        </div>

        <div className="right-column">
          {examsToShow.map((exam) => (
            <div className="Course" key={exam.id} onClick={() => handleExamClick(exam.id)}>
              <div className="calender">
                <div className='Date-top'></div>
                {exam.tableRows && exam.tableRows.length > 0 ? (
                  <div className="Date">
                    {new Date(exam.tableRows[0].startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                  </div>
                ) : (
                  <div className="Date">No Date Available</div>
                )}
              </div>
              <div className="Course-info">
                <a href={`/professionalexamview/${exam.id}`}><strong>{exam.examName}</strong></a>
                {/* Displaying data from tableRows */}
                {exam.tableRows && exam.tableRows.length > 0 ? (
                  exam.tableRows.map((row, index) => (
                    <div key={index} className="table-row">
                      <p>Start Date: {new Date(row.startDate).toLocaleDateString('en-CA')}</p>
                      <p>Duration: {row.duration}</p>
                      <p>Level: {row.level}</p>
                    </div>
                  ))
                ) : (
                  <div>No table rows available</div>
                )}
                <hr />
                <p1>Institute: <a href="#"><strong>{exam.university}</strong></a></p1>
              </div>
            </div>
          ))}
          {displayedExamsCount < exams.length && (
            <button className="more-exams" onClick={handleSeeAllExams}>SEE MORE EXAMS</button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProfessionalExams;
