import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard'
import '../../assets/css/admin/allcourses.css'

const DeleteCourses = () => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        fetchDeletedCourses();
    }, []);

    const fetchDeletedCourses = async () => {
        try {
            const response = await fetch('https://skillbackend.educationesupport.xyz/course/deleted');
            const data = await response.json();
            setCourses(data);
        } catch (error) {
            console.error('Error fetching deleted courses:', error);
        }
    };

    const handleRestore = async (courseId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/course/restore/${courseId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setCourses(courses.filter(course => course.id !== courseId));
                window.alert('Course Restored');
            } else {
                console.error('Failed to restore course');
            }
        } catch (error) {
            console.error('Error restoring course:', error);
        }
    };

    const handlePermanentDelete = async (courseId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/course/delete/permanent/${courseId}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setCourses(courses.filter(course => course.id !== courseId));
                window.alert('Course delete permanently');
            } else {
                console.error('Failed to delete course permanently');
            }
        } catch (error) {
            console.error('Error deleting course permanently:', error);
        }
    };

    return (

        <div>

            <AdminDashboard />

            <div className='AllViews-admin-course'>

                <div className="search-icon-admin admin-course-search">

                    <h1>DELETE COURSES</h1>
                    <form >
                        <input type="text" name="" id="" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                   
                </div>

                <div className="scroller-box">
                    <div className="table-course">
                    <table>
                            <thead>
                                <tr>
                                    <th>Course Name</th>
                                    <th>Institute Name</th>
                                    <th>Start Date</th>
                                    <th>Duration</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {courses.map((course) => (
                                    <tr key={course.id}>
                                        <td>{course.courseName}</td>
                                        <td>{course.university}</td>
                                        <p>{new Date(course.startDate).toLocaleDateString('en-CA')}</p>
                                        <td>{course.duration}</td>
                                        <td>
                                            <a href="#!" id='view-website'>WEBSITE</a>
                                        </td>
                                        <td id='action-buttons'>
                                            <a href="#!" id='restore-details' onClick={() => handleRestore(course.id)}>RESTORE</a>
                                            <a href="#!" id='del-details' onClick={() => handlePermanentDelete(course.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>



        </div>

    )

}

export default DeleteCourses