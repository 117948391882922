import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard';
import '../../assets/css/admin/allcourses.css';

const DeleteStudents = () => {
    const [students, setStudents] = useState([]);

    useEffect(() => {
        fetchDeletedStudents();
    }, []);

    const fetchDeletedStudents = async () => {
        try {
            const response = await fetch('https://skillbackend.educationesupport.xyz/std/deleted');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setStudents(data);
        } catch (error) {
            console.error('Error fetching deleted students:', error);
        }
    };

    const handleRestore = async (studentId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/std/restore/${studentId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setStudents(students.filter(student => student.id !== studentId));
                window.alert('Student restored successfully');
            } else {
                console.error('Failed to restore student');
                window.alert('Failed to restore student');
            }
        } catch (error) {
            console.error('Error restoring student:', error);
            window.alert('Error restoring student');
        }
    };

    const handlePermanentDelete = async (studentId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/std/delete/permanent/${studentId}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setStudents(students.filter(student => student.id !== studentId));
                window.alert('Student permanently deleted');
            } else {
                console.error('Failed to delete student permanently');
                window.alert('Failed to delete student permanently');
            }
        } catch (error) {
            console.error('Error deleting student permanently:', error);
            window.alert('Error deleting student permanently');
        }
    };

    return (
        <div>
            <AdminDashboard />
            <div className='AllViews-admin-course'>
                <div className="search-icon-admin admin-course-search">
                    <h1>DELETE STUDENTS</h1>
                    <form>
                        <input type="text" placeholder='Search Student' />
                        <input type="submit" value="SEARCH" />
                    </form>
                </div>
                <div className="scroller-box">
                    <div className="table-course">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>District</th>
                                    <th>Province</th>
                                    <th>Phone</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>School</th>
                                    <th>Course</th>
                                    <th>Category</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {students.length > 0 ? (
                                    students.map((student) => (
                                        <tr key={student.id}>
                                            <td>{student.name}</td>
                                            <td>{student.address}</td>
                                            <td>{student.district}</td>
                                            <td>{student.province}</td>
                                            <td>{student.phone}</td>
                                            <td>{student.mobile}</td>
                                            <td>{student.email}</td>
                                            <td>{student.school}</td>
                                            <td>{student.course}</td>
                                            <td>{student.category}</td>
                                            <td id='action-buttons'>
                                                <a href="#!" id='restore-details' onClick={() => handleRestore(student.id)}>RESTORE</a>
                                                <a href="#!" id='del-details' onClick={() => handlePermanentDelete(student.id)}>DELETE</a>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="11">No deleted students found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteStudents;
