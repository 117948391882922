import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard';
import '../../assets/css/admin/allcourses.css';

const PendingExternalDegrees = () => {
    const [externalDegrees, setExternalDegrees] = useState([]);

    useEffect(() => {
        fetchExternalDegrees();
    }, []);

    const fetchExternalDegrees = async () => {
        try {
            const response = await fetch('https://skillbackend.educationesupport.xyz/externalDegree/pending');
            const data = await response.json();
            setExternalDegrees(data);
        } catch (error) {
            console.error('Error fetching external degrees:', error);
        }
    };

    const handleDelete = async (externalDegreeId) => { 
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/externalDegree/delete/${externalDegreeId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setExternalDegrees(externalDegrees.filter(externalDegree => externalDegree.id !== externalDegreeId));
                window.alert('External Degree deleted');
            } else {
                console.error('Failed to delete external degree');
            }
        } catch (error) {
            console.error('Error deleting external degree:', error);
        }
    };

    const handleApprove = async (externalDegreeId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/externalDegree/approve/${externalDegreeId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setExternalDegrees(externalDegrees.filter(externalDegree => externalDegree.id !== externalDegreeId));
                window.alert('External Degree Approved');
            } else {
                console.error('Failed to approve external degree');
            }
        } catch (error) {
            console.error('Error approving external degree:', error);
        }
    };

    return (
        <div>
            <AdminDashboard />
            <div className='AllViews-admin-course'>
                <div className="search-icon-admin admin-course-search">
                    <h1>PENDING EXTERNAL DEGREES</h1>
                    <form>
                        <input type="text" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                </div>
                <div className="scroller-box">
                    <div className="table-course">
                        <table>
                            <thead>
                                <tr>
                                    <th>External Degree Name</th>
                                    <th>Institute Name</th>
                                    <th>Start Date</th>
                                    <th>Duration</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {externalDegrees.map((externalDegree) => (
                                    <tr key={externalDegree.id}>
                                        <td>{externalDegree.courseName}</td>
                                        <td>{externalDegree.university}</td>
                                        <td>{new Date(externalDegree.startDate).toLocaleDateString('en-CA')}</td>
                                        <td>{externalDegree.duration}</td>
                                        <td><a href="#!" id='view-website'>WEBSITE</a></td>
                                        <td id='action-buttons'>
                                            <a href="#!" id='app-details' onClick={() => handleApprove(externalDegree.id)}>APPROVE</a>
                                            <a href="#!" id='del-details' onClick={() => handleDelete(externalDegree.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PendingExternalDegrees;
