import React, { useEffect, useState } from 'react';
import InstituteDashboard from './InstituteDashboard';
import '../../assets/css/Institute/CreateCourse.css';
import axios from 'axios';

const CreateCourse = () => {
  const institute_id = sessionStorage.getItem('id');

  const [formData, setFormData] = useState({
    university: '',
    universityType: '',
    courseName: '',
    level: '',
    duration: '',
    startDate: '',
    category: '',
    specification: '',
    courseFee: '',
    careerOpportunities: '',
    industry: '',
    cologo: null,
    codescription: '',
    instituteAddress: '',
    institutePhone: '',
    instituteEmail: '',
    instituteLocation: ''
  });

  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);
  
  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [newLevel, setNewLevel] = useState('');

  useEffect(() => {
    const fetchInstituteDetails = async () => {
      try {
        const response = await axios.get(`https://skillbackend.educationesupport.xyz/ins/one/${institute_id}`);
        const institute = response.data[0];
        setFormData(prevData => ({
          ...prevData,
          university: institute.in_name || '',
          instituteAddress: institute.address || '',
          institutePhone: institute.tel || '',
          instituteEmail: institute.email || '',
          universityType: institute.type || ''
        }));
      } catch (error) {
        console.error('Error fetching institute details:', error);
      }
    };

    const fetchCategories = async () => {
      try {
          const response = await fetch('https://skillbackend.educationesupport.xyz/category/all');
          const data = await response.json();
          setCategories(data);
      } catch (error) {
          console.error('Error fetching Categories:', error);
      }
  };

  const fetchLevels = async () => {
    try {
        const response = await fetch('https://skillbackend.educationesupport.xyz/level/all');
        const data = await response.json();
        setLevels(data);
    } catch (error) {
        console.error('Error fetching Levels:', error);
    }
};

    fetchInstituteDetails();
    fetchCategories();
    fetchLevels();
  }, [institute_id]);

 
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'file' ? files[0] : value
    }));
  };

  // Fetch categories function
  const fetchCategories = async () => {
    try {
        const response = await fetch('https://skillbackend.educationesupport.xyz/category/all');
        const data = await response.json();
        setCategories(data);
    } catch (error) {
        console.error('Error fetching Categories:', error);
    }
};

  // Fetch levels function
  const fetchLevels = async () => {
    try {
        const response = await fetch('https://skillbackend.educationesupport.xyz/level/all');
        const data = await response.json();
        setLevels(data);
    } catch (error) {
        console.error('Error fetching Levels:', error);
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields or duplicates before submitting
    if (!formData.courseName) {
      alert('Course name is required.');
      return;
    }

    // Example check for duplicates - implement as needed
    const existingCourse = await fetch(`https://skillbackend.educationesupport.xyz/course/check?name=${formData.courseName}`);
    const existingCourseData = await existingCourse.json();
    
    if (existingCourseData.exists) {
      alert('A course with this name already exists.');
      return;
    }

    const formDataToSend = new FormData();

    for (const key in formData) {
      if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    }

    formDataToSend.append('institute_id', institute_id);

    try {
      const response = await fetch('https://skillbackend.educationesupport.xyz/course/create', {
        method: 'POST',
        body: formDataToSend
      });

      const contentType = response.headers.get('Content-Type');
      let data;

      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else {
        data = await response.text();
      }

      if (response.ok) {
        console.log('Form submitted:', data);
        window.alert('Course created successfully!');
        await fetchCategories(); // Refresh categories after creating a course
        await fetchLevels();
      } else {
        console.error('Error response:', data);
        window.alert(data.message || 'Failed to create course. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      window.alert('Failed to create course. Please try again.');
    }
  };

  const addCategory = async () => {
    if (!newCategory) {
      alert('Category name is required');
      return;
    }
  
    try {
      const response = await axios.post('https://skillbackend.educationesupport.xyz/category/add', {
        category: newCategory.trim(),
      });
  
      if (response.status === 201) {
        alert('Category added successfully');
        setNewCategory(''); 
        closeModal();
        
        // Fetch updated categories after adding a new category
        await fetchCategories(); // Re-fetch categories after adding a new one
      } else {
        alert('Failed to add category');
      }
    } catch (error) {
      console.error('Error adding category:', error);
      alert(error.response?.data?.error || 'Error adding category'); 
    }
  };
  
  const addLevel = async () => {
    if (!newLevel) {
      alert('Level name is required');
      return;
    }
  
    try {
      const response = await axios.post('https://skillbackend.educationesupport.xyz/level/add', {
        level: newLevel.trim(),  // Corrected to 'level'
      });
  
      if (response.status === 201) {
        alert('Level added successfully');
        setNewLevel(''); 
        closeModal();
        await fetchLevels(); // Re-fetch levels after adding a new one
      } else {
        alert('Failed to add level');
      }
    } catch (error) {
      console.error('Error adding level:', error);
      alert(error.response?.data?.error || 'Error adding level'); 
    }
  };
  
 
  
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <main className='institute-create-course'>
      <InstituteDashboard />
      <div className="head-content-text">
        <h4>ADD Course</h4>
      </div>
      <div className="sa-container">
        <form onSubmit={handleSubmit}>
          <div className="new">
            <div className="formgroup">
              <i className="fas fa-building"></i>
              <input
                type="text"
                id="university"
                name="university"
                value={formData.university}
                onChange={handleChange}
                placeholder="University / Institute Name"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-university"></i>
              <input
                type="text"
                id="universityType"
                name="universityType"
                value={formData.universityType}
                onChange={handleChange}
                placeholder="University / Institute Type"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-book"></i>
              <input
                type="text"
                id="courseName"
                name="courseName"
                value={formData.courseName}
                onChange={handleChange}
                placeholder="Course/ Degree Name"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-book-reader"></i>
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                placeholder="Category"
              >
                <option value="" disabled>Select Course Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.category}>
                    {category.category}
                  </option>
                ))}
              </select>

              <button type="button" className="categorybutton" onClick={openModal}>
                Add Category
              </button>

              {/* Category Modal */}
              {showModal && (
                <div className="cmodal">
                  <div className="cmodal-content">
                    <h2>Add New Category</h2>
                    <input
                      type="text"
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                      placeholder="Enter category name"
                    />
                    <div>
                      <button onClick={addCategory}>Add Category</button>
                      <button onClick={closeModal}>Cancel</button>
                    </div>
                  </div>
                </div>
              )}
            </div>

             <div className="formgroup">
              <i className="fas fa-layer-group"></i>
              <select
                id="level"
                name="level"
                value={formData.level}
                onChange={handleChange}
              >
              <option value="" disabled>Select Level </option>
                {levels.map((level) => (
                  <option key={level.id} value={level.level}>
                    {level.level}
                  </option>
                ))}
              </select>

              <button type="button" className="categorybutton" onClick={openModal}>
                Add Level
                </button>

                {showModal && (
                <div className="cmodal">
                  <div className="cmodal-content">
                    <h2>Add New Level</h2>
                    <input
                      type="text"
                      value={newLevel}
                      onChange={(e) => setNewLevel(e.target.value)}
                      placeholder="Enter level name"
                    />
                    <div>
                      <button onClick={addLevel}>Add Level</button>
                      <button onClick={closeModal}>Cancel</button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="formgroup">
              <i className="fas fa-hourglass-start"></i>
              <input
                type="text"
                id="duration"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                placeholder="Duration"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-calendar-alt"></i>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                placeholder="Start Date"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-cogs"></i>
              <input
                type="text"
                id="specification"
                name="specification"
                value={formData.specification}
                onChange={handleChange}
                placeholder="Specification"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-money-bill-wave"></i>
              <input
                type="text"
                id="courseFee"
                name="courseFee"
                value={formData.courseFee}
                onChange={handleChange}
                placeholder="Course Fee"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-briefcase"></i>
              <input
                type="text"
                id="careerOpportunities"
                name="careerOpportunities"
                value={formData.careerOpportunities}
                onChange={handleChange}
                placeholder="Career Opportunities"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-industry"></i>
              <input
                type="text"
                id="industry"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                placeholder="Industry"
              />
            </div>

            <div className="formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Drop Course Image</span>
                <input type="file" accept="image/*" id="file-input" name="cologo" onChange={handleChange} />
              </label>
            </div>

            <div className="formgroup">
              <textarea name="codescription" placeholder="Enter the description of the Course..." onChange={handleChange} value={formData.codescription} />
            </div>

          </div>
          <button type="submit" className="button">Submit</button>
        </form>
      </div>
    </main>
  );
};

export default CreateCourse;
