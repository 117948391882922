import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import SecondNav from '../components/SecondNav';
import { useParams } from 'react-router';
import axios from 'axios';
import ApplyForm from '../components/ApplyNow';
import '../assets/css/Local.css'

const LocaljobView = () => {
  const { id } = useParams();
  const [Job, setLJob] = useState(null);

  const getData = () => {
    axios.get(`https://skillbackend.educationesupport.xyz/ljobs/getone/${id}`).then((response) => {
      console.log(response.data[0]);
      setLJob(response.data[0]);
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    getData();
  }, [id]);


  if (!Job) {
    return <p>Loading...</p>;
  }

  const handleEmail = () => {
    window.location.href = `mailto:${setLJob.email}?subject=Apply for the job`;
  };

  return (
    <div>
      <SecondNav />

      <div className="content-local-job">

        <div className="local-job-view-1">

          <div className="image-in-local">
            {Job.img && <img src={`https://skillbackend.educationesupport.xyz/Upload/images/${Job.img}`} alt='' width={550} />}
          </div>

          <div className="text-local-all">

            <div className="local-row01">

              <div>

                <p id='test-head' ><i class="fa-solid fa-at"></i>{Job.jobtit}</p>

                <div className="text-marge">
                  <p id='test-head-post'>Posted by {Job.cname}</p>
                  <p id='test-head-post'> {Job.cdate}</p>
                </div>

                <div className="test-loacl-details">

                  <div className="contect-wrap-1-local">

                    <div className="contect-1-local">
                      <p id='text-seen'>Job Type</p>
                      <p id="send-marge-local">{Job.jobtype}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Job Level</p>
                      <p id="send-marge-local">{Job.joblevel}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Salary</p>
                      <p id="send-marge-local">{Job.salary}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Close Date</p>
                      <p id="send-marge-local">{Job.cdate}</p>
                    </div>
                  </div>

                  <div className="contect-wrap-1-local">

                    <div className="contect-1-local">
                      <p id='text-seen'>Company Name</p>
                      <p id="send-marge-local">{Job.jobtype}</p>
                    </div>



                    <div className="contect-1-local">
                      <p id='text-seen'>Contact</p>
                      <p id="send-marge-local">{Job.contact}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Email</p>
                      <p id="send-marge-local">{Job.email}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Company Web</p>
                      <p id="send-marge-local">{Job.web}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Company Address</p>
                      <p id="send-marge-local">{Job.caddr}</p>
                    </div>
                  </div>

                </div>

                <div className="contect-wrap-1-local">

                  <div className="contect-1-local">
                    <p id='text-seen'>About Jon</p>
                    <p id="send-marge-local">{Job._desc}</p>
                  </div>
                </div>


              </div>

            </div>

          </div>
        </div>


        <div className="applyConLocal">

          <div className="text-lo">
            <h2>Apply Now</h2>
            <hr id="local-app" />
          </div>

          <button id="button-up" onClick={handleEmail}>
            SEND DETAILS
          </button>

        </div>

      </div>


      
      <Footer />
    </div>
  );
};

export default LocaljobView;
