import React, { useState } from 'react';
import '../../assets/css/admin/login.css';
import Img from '../../assets/images/skillslogo.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import Swal from 'sweetalert2'

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;

        axios.post('https://skillbackend.educationesupport.xyz/admin/login', { email: email, password: password }).then((response) => {
            if (response.data.message === "Invalid") {
                Swal.fire({
                    title: "Invalid Email or Password",
                    icon: "error",
                });
            } else {

                sessionStorage.setItem('aid', response.data.id);
                sessionStorage.setItem('aname', response.data.name);
                Swal.fire({
                    title: "Login Successfull",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                }).then(() => {

                    window.location.href = "/admindc";
                });
                ;
            }
        }).catch((err) => {
            Swal.fire({
                title: "Invalid Email or Password",
                icon: "error",
            });
        })
    }

    return (
        <div className="login-container">
            <div className="login-box">
                <div className="logo-section">
                    <a href="/"><img src={Img} alt="logo.png" width={150} /></a>
                </div>
                <h2>ADMIN LOGIN</h2>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />
                    </div>
                    <div className="input-group password-container">
                        <label htmlFor="password">Password</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            required
                        />
                        <span
                            className="toggle-visibility"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? <FaEye /> : < FaEyeSlash />}
                        </span>
                    </div>

                    <button type="submit" className="login-button" style={{ width: "100%" }}>Sign In</button>
                </form>

            </div>
        </div>
    );
};

export default Login;
