import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/st_register.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer'; 
import Swal from 'sweetalert2';
import uniqid from 'uniqid'; 

const CreateApplication = () => {
  const [formData, setFormData] = useState({
    id: uniqid(), 
    appName: '',
    ownerName: '',
    ownerEmail: '',
    contact: '',
    link: '',  
    application: null,
    description: '',
    expire_time: ''
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); 

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "application" && files.length > 0) {
      const file = files[0];
      if (file.type !== "application/pdf") {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Invalid File Type',
          text: 'Please upload a valid PDF file.',
          showConfirmButton: true,
        });
        return;
      }
      setFormData((prevData) => ({
        ...prevData,
        application: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });
    
    fetch('https://skillbackend.educationesupport.xyz/applications/create', { 
      method: 'POST',
      body: data,
    })
      .then((response) => {
        if (response.status === 400) {
          return response.text().then((message) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Oops...',
              text: message,
              showConfirmButton: true,
            });
          });
        } else if (response.ok) {
          return response.text().then((data) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Application Added Successfully',
              text: 'Your PDF application has been added successfully.',
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              navigate('/applications'); 
            });
          });
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: true,
        });
      });
  };

  return (
    <main>
      <SecondNav />
      <div className="head-content-text">
        <h4>ADD Applications</h4>
      </div>
      <div className="r_container">
        <form onSubmit={handleSubmit}>
          <div className="new">
            <div className="formgroup">
              <i className="fas fa-list-alt"></i>
              <input type="text" id="appName" name="appName" value={formData.appName} onChange={handleChange} placeholder="Application Name" required />
            </div>

            <div className="formgroup">
              <i className="fas fa-user"></i>
              <input type="text" id="ownerName" name="ownerName" value={formData.ownerName} onChange={handleChange} placeholder="Owner Name" required />
            </div>

            <div className="formgroup">
              <i className="fas fa-envelope"></i>
              <input type="email" id="ownerEmail" name="ownerEmail" value={formData.ownerEmail} onChange={handleChange} placeholder="Owner email address" required />
            </div>

            <div className="formgroup">
              <i className="fas fa-phone"></i>
              <input type="tel" id="contact" name="contact" value={formData.contact} onChange={handleChange} placeholder="Owner contact no." required />
            </div>

            <div className="formgroup">
              <i className="fas fa-link"></i>
              <input type="url" id="link" name="link" value={formData.link} onChange={handleChange} placeholder="Add Application Link (optional)" />
            </div>

            <div className="formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Drop the PDF Application</span>
                <input type="file" accept=".pdf" id="file-input" name="application" onChange={handleChange} />
              </label>
            </div>

            <div className="formgroup textarea">
              <textarea name="description" placeholder="Enter the description for the Application..." onChange={handleChange} value={formData.description}></textarea>
            </div>

            <div className="formgroup">
            <i className="fas fa-clock"></i>
              <input
                type="datetime-local"
                id="expire_time"
                name="expire_time"
                placeholder="Select Due Date & Time"
                value={formData.expire_time}
                onChange={handleChange}
                required
              />
            </div>

          </div>
          <button type="submit">Add</button>
        </form>
      </div>
      <Footer />
    </main>
  );
};

export default CreateApplication;
