import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/courses.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';
import axios from 'axios';

const Scholarships = () => {
    const [isUniversityExpanded, setUniversityExpanded] = useState(false);
    const [isCountryExpanded, setCountryExpanded] = useState(false);
    const [scholarships, setScholarships] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredScholarships, setFilteredScholarships] = useState([]);
    const [filters, setFilters] = useState({
        university: '', 
        country: '',
        search: '' // Include search in filters
    });
    const [displayedScholarshipsCount, setDisplayedScholarshipsCount] = useState(4);
    const navigate = useNavigate();

    const toggleUniversity = () => setUniversityExpanded(!isUniversityExpanded);
    const toggleCountry = () => setCountryExpanded(!isCountryExpanded);

    const fetchScholarships = async () => { 
        try {
            const query = new URLSearchParams(filters).toString();
            const response = await fetch(`https://skillbackend.educationesupport.xyz/scholarships/all?${query}`);
            if (response.ok) {
                const data = await response.json();
                setScholarships(data);
            } else {
                console.error('Error fetching Scholarships:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching Scholarships:', error);
        }
    };
    
    useEffect(() => {
        fetchScholarships();
    }, [filters]);

    
    useEffect(() => {
        if (search.trim() === '') {
            setFilteredScholarships(scholarships); // Show all if search is empty
        } else {
            setFilteredScholarships(
                scholarships.filter(scholarship => 
                    scholarship.sProgrammeName && // Use the correct field for searching
                    scholarship.sProgrammeName.toLowerCase().includes(search.toLowerCase())
                )
            );
        }
    }, [search, scholarships]); // Filter scholarships whenever search or scholarships changes
    
    const handleScholarshipsClick = (id) => {
        navigate(`/scholarshipview/${id}`);
    };
    
    const handleFilterChange = (type, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [type]: value
        }));
    };
    
    const handleSeeAllScholarships = () => {
        setDisplayedScholarshipsCount((prevCount) => prevCount + 4);
    };

    // Use filteredScholarships to show the scholarships
    const ScholarshipsToShow = filteredScholarships.slice(0, displayedScholarshipsCount);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setFilters((prevFilters) => ({
            ...prevFilters,
            search: search // Update filters with the search term
        }));
    };

    return (
        <div> 
            <SecondNav/>
            <div className="Container">   
                <div className="left-column">
                    <div className="containerBoxOne-course">
                    <div className="content-warp">
                        <h4>FIND YOUR SCHOLARSHIP</h4>
                        <form id='formInstitutes' onSubmit={handleSubmit}>
                            <input 
                                type="text" 
                                placeholder="Enter Course Name" 
                                onChange={(e) => setSearch(e.target.value)} 
                            />
                            <input type="submit" value="Search" />
                        </form>
                    </div>
                </div>
                    {/* <div className={`Institution-section ${isClassExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleClass}>
                            Class Type <span className="expand-arrow">{isClassExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isClassExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-government" value="Individual" onClick={() => handleFilterChange('classType', 'Individual')} /></li>
                            <li><input type="button" className="button-private" value="Group" onClick={() => handleFilterChange('classType', 'Group')} /></li>
                            <li><input type="button" className="button-phd" value="Hall" onClick={() => handleFilterChange('classType', 'Hall')} /></li> 
                            <li><input type="button" className="button-hnd" value="All" onClick={() => handleFilterChange('classType', 'All')} /></li> 
                            <li><input type="button" className="button-postgraduate-diploma" value="Other" onClick={() => handleFilterChange('classType', 'Other')} /></li> 
                        </ul>
                        <hr />
                    </div> */}
                  <div className={`countriesSection ${isCountryExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleCountry}>
                        COUNTRY <span className="expand-arrow">{isCountryExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list1 ${isCountryExpanded ? 'active' : ''}`}>
                        <li><input type="button" className="buttonAustralia" value="Australia" onClick={() => handleFilterChange('country', 'Australia')} /></li>
                        <li><input type="button" className="buttonCanada" value="Canada" onClick={() => handleFilterChange('country', 'Canada')} /></li>
                        <li><input type="button" className="buttonChina" value="China" onClick={() => handleFilterChange('country', 'China')} /></li>
                        <li><input type="button" className="buttonFrance" value="France" onClick={() => handleFilterChange('country', 'France')} /></li>
                        <li><input type="button" className="buttonGermany" value="Germany" onClick={() => handleFilterChange('country', 'Germany')} /></li>
                        <li><input type="button" className="buttonIndia" value="India" onClick={() => handleFilterChange('country', 'India')} /></li>
                        <li><input type="button" className="buttonJapan" value="Japan" onClick={() => handleFilterChange('country', 'Japan')} /></li>
                        <li><input type="button" className="buttonUK" value="UK" onClick={() => handleFilterChange('country', 'UK')} /></li>
                        <li><input type="button" className="buttonUSA" value="USA" onClick={() => handleFilterChange('country', 'USA')} /></li>
                        </ul>
                        <hr />
                    </div>

                    <div className={`Levels-section ${isUniversityExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleUniversity}>
                        UNIVERSITY / INSTITUTE <span className="expand-arrow">{isUniversityExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isUniversityExpanded ? 'active' : ''}`}>
                        <li><input type="button" className="button-foundation" value="AAT Sri Lanka" onClick={() => handleFilterChange('university', 'AAT Sri Lanka')} /></li>
                        <li><input type="button" className="button-training" value="SLIIT" onClick={() => handleFilterChange('level', 'SLIIT')} /></li>
                        <li><input type="button" className="button-certificate" value="University of Colombo" onClick={() => handleFilterChange('level', 'University of Colombo')} /></li>
                        <li><input type="button" className="button-diploma" value="University of Moratuwa" onClick={() => handleFilterChange('level', 'University of Moratuwa')} /></li>
                        <li><input type="button" className="button-advanced-diploma" value="Western Sydney University" onClick={() => handleFilterChange('level', 'Western Sydney University')} /></li>
                        <li><input type="button" className="button-hnd" value="Curtin University" onClick={() => handleFilterChange('level', 'Curtin University')} /></li>
                        <li><input type="button" className="button-degree" value="IIT" onClick={() => handleFilterChange('level', 'IIT')} /></li>
                        <li><input type="button" className="button-bachelor" value="CSCT Campus" onClick={() => handleFilterChange('level', 'CSCT Campus')} /></li>
                        <li><input type="button" className="button-postgraduate-diploma" value="AIC" onClick={() => handleFilterChange('level', 'AIC')} /></li>
                        <li><input type="button" className="button-masters" value="ESOFT" onClick={() => handleFilterChange('level', 'ESOFT')} /></li>
                        <li><input type="button" className="button-phd" value="SLIIT" onClick={() => handleFilterChange('level', 'SLIIT')} /></li>
                        </ul>
                    </div>
                    
                </div>

                <div className="right-column">
                    {ScholarshipsToShow.map(Scholarships => (
                        <div className="Course" key={Scholarships.id} onClick={() => handleScholarshipsClick(Scholarships.id)}>
                        {/* <div className="calender">
                            <div className='Date-top'></div>
                            <div className="Date">{new Date(Scholarships.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</div>
                        </div> */}
                        <div className="Course-info">
                            <a href={`/scholarshipview/${Scholarships.id}`}><strong>{Scholarships.sProgrammeName}</strong></a>
                            <p>Valid Till: {new Date(Scholarships.closingDate).toLocaleDateString('en-CA')}</p>
                            <p>Country: {Scholarships.country}</p>
                            <hr />
                            <p1>By : <a href="#"><strong>{Scholarships.university}</strong></a></p1>
                        </div>
                        </div>
                    ))}
                    {displayedScholarshipsCount < Scholarships.length && (
                        <button className="more-scholarships" onClick={handleSeeAllScholarships}>SEE MORE SCHOLARSHIPS</button>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Scholarships;
