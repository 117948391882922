import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard';
import '../../assets/css/admin/allcourses.css';

const AllScholarships = () => {
    const [Scholarships, setScholarships] = useState([]);

    useEffect(() => {
        fetchScholarships();
    }, []);


    const fetchScholarships = async () => {
        try {
            const response = await fetch('https://skillbackend.educationesupport.xyz/scholarships/all');
            const data = await response.json();
            setScholarships(data);
        } catch (error) {
            console.error('Error fetching Scholarships:', error);
        }
    };

    const handleDelete = async (ScholarshipsId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/scholarships/delete/${ScholarshipsId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setScholarships(Scholarships.filter(Scholarships => Scholarships.id !== ScholarshipsId));
            } else {
                console.error('Failed to delete Scholarship');
            }
        } catch (error) {
            console.error('Error deleting Scholarship:', error);
        }
    };

    return (
        <div>
            <AdminDashboard />

            <div className='AllViews-admin-course'>
                <div className="search-icon-admin admin-course-search">
                    <h1>ALL SCHOLARSHIPS</h1>
                    <form>
                        <input type="text" name="" id="" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                </div>

                <div className="scroller-box">
                    <div className="table-course">
                    <table> 
                    <thead>
                                <tr>
                                    <th>Scholarship Programme Name</th>
                                    <th>Country</th>
                                    <th>Offered By</th>
                                    <th>Closing Date </th>
                                    <th>Contact No.</th>
                                    <th>Email</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {Scholarships.map((Scholarships) => (
                                    <tr key={Scholarships.id}>
                                        <td>{Scholarships.sProgrammeName}</td>
                                        <td>{Scholarships.country}</td>              
                                        <td>{Scholarships.university}</td>
                                        <td>{new Date(Scholarships.closingDate).toLocaleDateString('en-CA')}</td>
                                        <td>{Scholarships.institutePhone}</td>
                                        <td>{Scholarships.instituteEmail}</td>
                                        <td>
                                            <a href={Scholarships.instituteWebsite} target="_blank" rel="noopener noreferrer">
                                                Visit Website
                                            </a>
                                        </td>
                                        <td id='action-buttons'>
                                            {/* <a href="#!" id='man-details' onClick={() => handleEdit(course.id)}>EDIT</a> */}
                                            <a href="#!" id='del-details' onClick={() => handleDelete(Scholarships.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllScholarships;
