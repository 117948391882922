import React, { useState, useEffect } from 'react'
import AdminDashboard from './AdminDashboard'

import '../../assets/css/admin/InstituteAll.css'
import axios from 'axios'
import 'intl-tel-input/build/css/intlTelInput.css'; // Assuming CSS import is necessary
import Swal from 'sweetalert2'

const LJobCateView = () => {



    const [institute, setInstitute] = useState([])

    const getAllDate = () => {
        axios.get('https://skillbackend.educationesupport.xyz/ljobcate/get').then((response) => {
            console.log(response.data)
            setInstitute(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {

        getAllDate()

    }, [])

    const UpdateDelete = (id) => {
        console.log(id);

        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to Delete this?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://skillbackend.educationesupport.xyz/ljobcate/delete/${id}`).then((response) => {
                    console.log(response.data);
                    getAllDate();
                }).catch((err) => {
                    console.log(err);
                });
            }
        });




    }


    // const UpdateApprove = (e, id, email, password, name) => {
    //   e.preventDefault();
    //   console.log(id);

    //   const ddata = {
    //     approve: "approve"
    //   }

    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "Do you want to approve this?",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes, Approved it!"
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       axios.put('https://skillbackend.educationesupport.xyz/ins/approve/' + id, ddata).then((response) => {
    //         console.log(response.data);
    //         getAllDate()



    //       }).catch((err) => {
    //         console.log(err);
    //       })
    //       Swal.fire({
    //         title: "Approved!",
    //         text: "Your file has been Approved.",
    //         icon: "success"
    //       });
    //     }
    //   });


    // }



    const [newitem, setItem] = useState('new-item')
    const [newNO, serNO] = useState('new-no')

    function getDate() {
        let today = new Date();
        let dd = today.getDate();

        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        } if (mm < 10) {
            mm = '0' + mm;
        }
        return today = mm + '-' + dd + '-' + yyyy;
    }

    const dateset = getDate();

    const sortedInstitutes = institute.sort((a, b) => {
        // Sort by date in descending order (most recent first)
        return new Date(b.date) - new Date(a.date);
    });

    const [searchTerm, setSearchTerm] = useState('')

    const handleSearch = (e) => {
        e.preventDefault()
        setSearchTerm(e.target.value)
    }

    const filteredInstitutes = sortedInstitutes.filter((institute) =>
        institute.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [cate, setCate] = useState('');

    const handleAdd = (e) => {

        e.preventDefault();

        axios.post('https://skillbackend.educationesupport.xyz/ljobcate/add', { name: cate }).then((response) => {
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500
            });
            getAllDate()
        }).catch((error) => {
            console.log('error')
        })

    }






    return (

        <div>

            <AdminDashboard />

            <div className='AllViews-admin'>

                <div className="search-icon-admin admin-institute-search">

                    <h1 style={{ textTransform: "uppercase" }}>Local job category</h1>
                    <form >
                        <input type="text" name="" onChange={handleSearch} id="" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>

                </div>

                <div className="add-column search-icon-admin admin-institute-search">

                    <form onSubmit={handleAdd}>

                        <input type="text" name="cate" placeholder='Add foreign job category' id="" onChange={(e) => {
                            setCate(e.target.value)
                        }} />
                        <input type="submit" value="ADD CATEGORY" />
                    </form>

                </div>


                <div className="scroller-box">
                    <div className="table-institute">
                        <table>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Category</th>
                                    <th>Action</th>

                                </tr>
                            </thead>

                            <tbody>

                                {filteredInstitutes.length > 0 ? (
                                    filteredInstitutes.map((institute) => (
                                        <tr key={institute.id}>
                                            <td>
                                                {institute.id}
                                            </td>

                                            <td>
                                                {institute.name}
                                            </td>
                                            <td id="action-buttons">

                                                <button
                                                    onClick={(e) => {
                                                        UpdateDelete(institute.id);
                                                    }}
                                                    id="del-details"
                                                >
                                                    DELETE
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" style={{ textAlign: 'center', padding: '10px' }}>
                                            Search data not found
                                        </td>
                                    </tr>
                                )}



                            </tbody>
                        </table>

                    </div>

                </div>

            </div>





        </div>
    )
}

export default LJobCateView