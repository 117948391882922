import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/studyabroad.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';

const StudyAbroad = () => {
  const [isCountriesExpanded, setCountriesExpanded] = useState(false);
  const [isCategoriesExpanded, setCategoriesExpanded] = useState(false);
  const [isLevelsExpanded, setLevelsExpanded] = useState(false);
  const [courses, setCourses] = useState([]);
  const [search, setSearch] = useState(''); 
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]); 
  const [filters, setFilters] = useState({
    country: '',
    category: '',
    level: ''
  });
  const [displayedCoursesCount, setDisplayedCoursesCount] = useState(4);

  const navigate = useNavigate();

  const toggleCountries = () => setCountriesExpanded(!isCountriesExpanded);
  const toggleCategories = () => setCategoriesExpanded(!isCategoriesExpanded);
  const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);

  const fetchCourses = async () => {
    try {
      const query = new URLSearchParams(filters).toString();
      const response = await fetch(`https://skillbackend.educationesupport.xyz/studyabroad/all?${query}`);
      if (response.ok) {
        const data = await response.json();
        setCourses(data);
        setFilteredCourses(data); // Update both courses and filteredCourses
      } else {
        console.error('Failed to fetch courses');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch('https://skillbackend.educationesupport.xyz/category/all');
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      } else {
        console.error('Error fetching categories:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchLevels = async () => { 
    try {
      const response = await fetch('https://skillbackend.educationesupport.xyz/level/all');
      if (response.ok) {
        const data = await response.json();
        setLevels(data);
      } else {
        console.error('Error fetching levels:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching levels:', error);
    }
  };

  useEffect(() => {
    fetchCourses();
    fetchCategories();
    fetchLevels(); 
  }, [filters]);

  const handleCourseClick = (id) => {
    navigate(`/studyabroadview/${id}`);
  };

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value
    }));
  };

  useEffect(() => {
    if (search.trim() === '') {
      setFilteredCourses(courses); // Reset to all courses if search is empty
    } else {
      setFilteredCourses(
        courses.filter(course => 
          course.courseName && 
          typeof course.courseName === 'string' && 
          course.courseName.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, courses]);

  const handleSeeAllCourses = () => {
    setDisplayedCoursesCount((prevCount) => prevCount + 4);
  };

  const coursesToShow = filteredCourses.slice(0, displayedCoursesCount);

  // Define an array of fixed colors
  const fixedColors = [
    '#ff6347', '#4682b4', '#32cd32', '#ffa500', '#8a2be2',
    '#3a5acd', '#ff1493', '#00bfff', '#ff4500', '#6a5acd',
    '#ff6347', '#ff69b4', '#36d85c', '#ffa6c0', '#ff8c00',
    '#6b8e23', '#4682b4', '#ff1493', '#ff7f50', '#17a2b8',
    '#dc143c', '#d2691e', '#cd5c5c', '#00e68c', '#7b68ee',
    '#9acd32', '#8b4513', '#5fdab9', '#b22222', '#c71585',
    '#4169e1', '#32cd32', '#6a5acd', '#ff4500', '#ff8c00',
    '#6495ed', '#e9967a', '#9932cc', '#ffd700', '#f5deb3',
    '#ffdead', '#c0c0c0', '#708090', '#2e8b57', '#7f00ff',
    '#ff1493', '#d8bfd8', '#ffd700', '#dc143c', '#ffe4e1',
    '#00bfff', '#4169e1', '#ff69b4', '#afeeee', '#98fb98',
    '#4682b4'
  ];

    // Function to handle form submit
    const handleSubmit = (e) => {
      e.preventDefault();
  };

  return (
    <div>
      <SecondNav />
      <div className="sContainer">
        <div className="left-column">
        <div className="containerBoxOne-course">
                    <div className="content-warp">
                        <h4>FIND YOUR COURSE</h4>
                        <form id='formInstitutes' onSubmit={handleSubmit}>
                            <input 
                                type="text" 
                                placeholder="Enter Course Name" 
                                onChange={(e) => setSearch(e.target.value)} 
                            />
                            <input type="submit" value="Search" />
                        </form>
                    </div>
                </div>
          <div className={`countriesSection ${isCountriesExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleCountries}>
              COUNTRY <span className="expand-arrow">{isCountriesExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list1 ${isCountriesExpanded ? 'active' : ''}`}>
              <li><input type="button" className="buttonAustralia" value="Australia" onClick={() => handleFilterChange('country', 'Australia')} /></li>
              <li><input type="button" className="buttonCanada" value="Canada" onClick={() => handleFilterChange('country', 'Canada')} /></li>
              <li><input type="button" className="buttonChina" value="China" onClick={() => handleFilterChange('country', 'China')} /></li>
              <li><input type="button" className="buttonFrance" value="France" onClick={() => handleFilterChange('country', 'France')} /></li>
              <li><input type="button" className="buttonGermany" value="Germany" onClick={() => handleFilterChange('country', 'Germany')} /></li>
              <li><input type="button" className="buttonIndia" value="India" onClick={() => handleFilterChange('country', 'India')} /></li>
              <li><input type="button" className="buttonJapan" value="Japan" onClick={() => handleFilterChange('country', 'Japan')} /></li>
              <li><input type="button" className="buttonUK" value="UK" onClick={() => handleFilterChange('country', 'UK')} /></li>
              <li><input type="button" className="buttonUSA" value="USA" onClick={() => handleFilterChange('country', 'USA')} /></li>
            </ul>
            <hr />
          </div>
          <div className={`Categories-section ${isCategoriesExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleCategories}>
            CATEGORIES <span className="expand-arrow">{isCategoriesExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filterlist ${isCategoriesExpanded ? 'active' : ''}`}>
              {categories.map((category, index) => (
                <li key={category.id}>
                  <input
                    type="button"
                    style={{ backgroundColor: fixedColors[index % fixedColors.length] }}
                    value={category.category}
                    onClick={() => handleFilterChange('category', category.category)}
                  />
                </li>
              ))}
            </ul>
            <hr />
          </div>
          <div className={`Levels-section ${isLevelsExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleLevels}>
            LEVELS <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
              {levels.map((level, index) => (
                <li key={level.id}>
                  <input
                    type="button"
                    style={{ backgroundColor: fixedColors[index % fixedColors.length] }} 
                    value={level.level} 
                    onClick={() => handleFilterChange('level', level.level)} 
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="right-column">
          {coursesToShow.map(course => (
            <div className="Course" key={course.id} onClick={() => handleCourseClick(course.id)}>
              <div className="calender">
                <div className='Date-top'></div>
                <div className="Date">{new Date(course.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</div>
              </div>
              <div className="Course-info">
                <a href={`/studyabroadview/${course.id}`}><strong>{course.courseName}</strong></a>
                {/* <p>Intakes: {course.intakes}</p> */}
                <p>Duration: {course.duration}</p>
                <p>Country: {course.country}</p>
                <p>Level: {course.level}</p>
                <p>Field: {course.category}</p>
                <hr />
                <p1>Institute: <a href="#"><strong>{course.university}</strong></a></p1>
              </div>
            </div>
          ))}
          {displayedCoursesCount < courses.length && (
            <button className="more-courses" onClick={handleSeeAllCourses}>SEE MORE COURSES</button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default StudyAbroad;
