import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/courses.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';
import axios from 'axios';

const GovCourses = () => {
  const [isCategoriesExpanded, setCategoriesExpanded] = useState(false);
  const [isLevelsExpanded, setLevelsExpanded] = useState(false);
  const [isInstitutionExpanded, setInstitutionExpanded] = useState(false);
  const [courses, setCourses] = useState([]);
  const [search, setSearch] = useState(''); 
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]); 
  const [filters, setFilters] = useState({
    universityType: '',
    category: '',
    level: ''
  });
  const [displayedCoursesCount, setDisplayedCoursesCount] = useState(4);

  const navigate = useNavigate();

  const toggleCategories = () => setCategoriesExpanded(!isCategoriesExpanded);
  const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);
  const toggleInstitution = () => setInstitutionExpanded(!isInstitutionExpanded);

  const fetchCourses = async () => {
    try {
      const query = new URLSearchParams(filters).toString();
      const response = await fetch(`https://skillbackend.educationesupport.xyz/course/all?${query}`);
      if (response.ok) {
        const data = await response.json();
        setCourses(data);
        setFilteredCourses(data); // Also set filteredCourses when fetching new data
      } else {
        console.error('Error fetching courses:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const BaseUrl = 'https://skillbackend.educationesupport.xyz/course/all';

  useEffect(() => {
    axios.get(`${BaseUrl}all`)
        .then(response => {
          setCourses(response.data);
          setFilteredCourses(response.data); 
        })
        .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (search.trim() === '') {
      setFilteredCourses(courses);
    } else {
      setFilteredCourses(
        courses.filter(course => 
          course.courseName && 
          typeof course.courseName === 'string' && 
          course.courseName.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, courses]);

  const fetchCategories = async () => {
    try {
      const response = await fetch('https://skillbackend.educationesupport.xyz/category/all');
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      } else {
        console.error('Error fetching categories:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchLevels = async () => {
    try {
      const response = await fetch('https://skillbackend.educationesupport.xyz/level/all');
      if (response.ok) {
        const data = await response.json();
        setLevels(data);
      } else {
        console.error('Error fetching levels:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching levels:', error);
    }
  };

  useEffect(() => {
    fetchCourses();
    fetchCategories();
    fetchLevels();
  }, [filters]);

  const handleCourseClick = (id) => {
    navigate(`/courseview/${id}`);
  };

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value
    }));
  };

  const handleSeeAllCourses = () => {
    setDisplayedCoursesCount((prevCount) => prevCount + 4);
  };

  const coursesToShow = filteredCourses.slice(0, displayedCoursesCount); // Use filteredCourses


  // Define an array of fixed colors
  const fixedColors = [
    '#ff6347', '#4682b4', '#32cd32', '#ffa500', '#8a2be2',
    '#3a5acd', '#ff1493', '#00bfff', '#ff4500', '#6a5acd',
    '#ff6347', '#ff69b4', '#36d85c', '#ffa6c0', '#ff8c00',
    '#6b8e23', '#4682b4', '#ff1493', '#ff7f50', '#17a2b8',
    '#dc143c', '#d2691e', '#cd5c5c', '#00e68c', '#7b68ee',
    '#9acd32', '#8b4513', '#5fdab9', '#b22222', '#c71585',
    '#4169e1', '#32cd32', '#6a5acd', '#ff4500', '#ff8c00',
    '#6495ed', '#e9967a', '#9932cc', '#ffd700', '#f5deb3',
    '#ffdead', '#c0c0c0', '#708090', '#2e8b57', '#7f00ff',
    '#ff1493', '#d8bfd8', '#ffd700', '#dc143c', '#ffe4e1',
    '#00bfff', '#4169e1', '#ff69b4', '#afeeee', '#98fb98',
    '#4682b4'
  ];

    // Function to handle form submit
    const handleSubmit = (e) => {
      e.preventDefault();
  };

  return (
    <div>
      <SecondNav />
      <div className="Container">
        <div className="left-column">
        <div className="containerBoxOne-course">
                    <div className="content-warp">
                        <h4>FIND YOUR COURSE</h4>
                        <form id='formInstitutes' onSubmit={handleSubmit}>
                            <input 
                                type="text" 
                                placeholder="Enter Course Name" 
                                onChange={(e) => setSearch(e.target.value)} 
                            />
                            <input type="submit" value="Search" />
                        </form>
                    </div>
                </div>
          <div className={`Institution-section ${isInstitutionExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleInstitution}>
              INSTITUTION TYPE <span className="expand-arrow">{isInstitutionExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list ${isInstitutionExpanded ? 'active' : ''}`}>
              <li><input type="button" className="button-government" value="Government" onClick={() => handleFilterChange('universityType', 'Government')} /></li>
              <li><input type="button" className="button-private" value="Private" onClick={() => handleFilterChange('universityType', 'Private')} /></li>
            </ul>
            <hr />
          </div>
          <div className={`Categories-section ${isCategoriesExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleCategories}>
              CATEGORIES <span className="expand-arrow">{isCategoriesExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filterlist ${isCategoriesExpanded ? 'active' : ''}`}>
              {categories.map((category, index) => (
                <li key={category.id}>
                  <input
                    type="button"
                    style={{ backgroundColor: fixedColors[index % fixedColors.length] }} // Use fixed colors
                    value={category.category}
                    onClick={() => handleFilterChange('category', category.category)}
                  />
                </li>
              ))}
            </ul>
            <hr />
          </div>
          <div className={`Levels-section ${isLevelsExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleLevels}>
              LEVELS <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
              {levels.map((level, index) => (
                <li key={level.id}>
                  <input
                    type="button"
                    style={{ backgroundColor: fixedColors[index % fixedColors.length] }} // Use fixed colors
                    value={level.level} // Assuming level object has a levelName field
                    onClick={() => handleFilterChange('level', level.levelName)} // Update based on your data structure
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="right-column">
          {coursesToShow.map(course => (
            <div className="Course" key={course.id} onClick={() => handleCourseClick(course.id)}>
              <div className="calender">
                <div className='Date-top'></div>
                <div className="Date">{new Date(course.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</div>
              </div>
              <div className="Course-info">
                <a href={`/courseview/${course.id}`}><strong>{course.courseName}</strong></a>
                <p>Start Date: {new Date(course.startDate).toLocaleDateString('en-CA')}</p>
                <p>Duration: {course.duration}</p>
                <p>Level: {course.level}</p>
                <p>Field: {course.category}</p>
                <hr />
                <p1>Institute: <a href="#"><strong>{course.university}</strong></a></p1>
              </div>
            </div>
          ))}
          {displayedCoursesCount < courses.length && (
            <button className="more-courses" onClick={handleSeeAllCourses}>SEE MORE COURSES</button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GovCourses;
