import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../assets/css/courseView.css';
import ApplyForm from '../components/ApplyNow';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';
import axios from 'axios';

const CourseView = () => { 
    const { id } = useParams();
    const [course, setCourse] = useState(null);

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const response = await axios.get(`https://skillbackend.educationesupport.xyz/course/${id}`);
                console.log(response.data);
                setCourse(response.data);
            } catch (error) {
                console.error('Error fetching course data:', error);
            }
        };

        fetchCourseData();
    }, [id]);

    if (!course) {
        return null;
    }

    return (
        <div>
            <SecondNav />
            <div className="c_container">
                <div className="c_leftcolumn">
                    <div className="programme">
                        <h2>{course.courseName}</h2>
                        <hr id="hr-programme" />
                        <div className="c-image">
                            {course.cologo && <img src={`https://skillbackend.educationesupport.xyz/Upload/images/courses/${course.cologo}`} alt={course.courseName} />}
                        </div>
                        <h3>Overview</h3>
                        <p id="p-overview">{course.codescription || 'No description available'}</p>
                    </div>

                    <div className="programmes">
                    <h3>RELATED COURSES</h3>
                    <hr id="hr-programme" />
                    <div className="courseitem">
                        <div className="coursetitle">
                        <a href="#"><strong>Master Of Business Management</strong></a>
                        </div>
                        <div className="coursedetails">Duration: 2 Years</div>
                        <hr />
                        <div className="courseins">By: <a href="#">{course.university}</a></div>
                    </div>
                    </div>
                    
                    <div className="programme">
                        <ApplyForm />
                    </div>
                </div>

                <div className="c_rightcolumn">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="icon-text">
                                        <i className="fa fa-university"></i>
                                        <span>Institute:</span>
                                    </div>
                                </td>
                                <td>
                                    <p>{course.university}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="icon-text">
                                        <i className="fa fa-clock"></i>
                                        <span>Duration:</span>
                                    </div>
                                </td>
                                <td>
                                    <p>{course.duration}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="icon-text">
                                        <i className="fa fa-calendar"></i>
                                        <span>Start Date: </span> <td></td>
                                    </div>
                                </td>
                                <td>
                                    <p>{new Date(course.startDate).toLocaleDateString('en-CA')}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="icon-text">
                                        <i className="fa fa-graduation-cap"></i>
                                        <span>Level:</span>
                                    </div>
                                </td>
                                <td>
                                    <p>{course.level}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="icon-text">
                                        <i className="fa fa-folder"></i>
                                        <span>Category:</span>
                                    </div>
                                </td>
                                <td>
                                    <p>{course.category}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="icon-text">
                                        <i className="fa fa-tags"></i>
                                        <span>Sub Category:</span>
                                    </div>
                                </td>
                                <td>
                                    <p>{course.specification}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="icon-text">
                                        <i className="fa fa-phone"></i>
                                        <span>Contact:</span>
                                    </div>
                                </td>
                                <td>
                                    <p>{course.institutePhone}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="icon-text">
                                        <i className="fa fa-map-marker"></i>
                                        <span>Location:</span>
                                    </div>
                                </td>
                                <td>
                                    <p>{course.instituteLocation}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="icon-text">
                                        <i className="fa fa-money-bill-alt"></i>
                                        <span>Course Fee:</span>
                                    </div>
                                </td>
                                <td>
                                    <p>{course.courseFee || 'Course fee details not available'}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CourseView;
