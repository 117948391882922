import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import '../../assets/css/Institute/CreateCourse.css';
import Swal from 'sweetalert2';
import uniqid from 'uniqid'; 

const AdminCreateTuition = () => {
    const [formData, setFormData] = useState({
      id: uniqid(), 
      className: '',
      classType: '',
      subject: '',
      district: '',
      classLevel: '',
      startDate: '',
      time: '',
      classFee: '',
      cbanner:null,
      description: '',
      teacherName: '',
      teacherQualification: '',
      teacherEmail: '',
      contact: '',
      location: '',
      });
    
    
      const navigate = useNavigate(); 
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        })); 
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        const data = new FormData();
        Object.keys(formData).forEach((key) => {
          data.append(key, formData[key]);
        });
        
        fetch('https://skillbackend.educationesupport.xyz/tuition/create', { 
          method: 'POST',
          body: data,
        })
          .then((response) => {
            if (response.status === 400) {
              return response.text().then((message) => {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Oops...',
                  text: message,
                  showConfirmButton: true,
                });
              });
            } else if (response.ok) {
              return response.text().then((data) => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Class Created Successfully',
                  text: 'Your class has been added successfully.',
                  showConfirmButton: false,
                  timer: 2500,
                }).then(() => {
                  navigate('/pendingtuition'); 
                });
              });
            } else {
              throw new Error('Network response was not ok.');
            }
          })
          .catch((error) => {
            console.error('Error:', error);
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              showConfirmButton: true,
            });
          });
      };
    

  return (
    <main className='institute-create-course'>
      <AdminDashboard />
      <div className="head-content-text">
        <h4>ADD Tuition</h4>
      </div>
      <div className="r_container">
        <form onSubmit={handleSubmit}>
          <div className="new">
            <div className="formgroup">
              <i className="fas fa-book-reader"></i>
              <input
                type="text"
                id="className"
                name="className"
                value={formData.className}
                onChange={handleChange}
                placeholder="Class Name"
                required
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-list-alt"></i>
              <select
                id="classType"
                name="classType"
                value={formData.classType}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select Class Type
                </option>
                <option value="Individual">Individual</option>
                <option value="Group">Group</option>
                <option value="Hall">Hall</option>
                <option value="All">All</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-book"></i>
              <select
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select Subject
                </option>
                <option value="English">English</option>
                <option value="Science">Science</option>
                <option value="Sinhala">Sinhala</option>
                <option value="Maths">Maths</option>
                <option value="Arts">Arts</option>
                <option value="IT">IT</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <select
                  id="district"
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select District
                  </option>
                  <option value="Ampara">Ampara</option>
                  <option value="Anuradhapura">Anuradhapura</option>
                  <option value="Badulla">Badulla</option>
                  <option value="Batticaloa">Batticaloa</option>
                  <option value="Colombo">Colombo</option>
                  <option value="Galle">Galle</option>
                  <option value="Gampaha">Gampaha</option>
                  <option value="Hambantota">Hambantota</option>
                  <option value="Jaffna">Jaffna</option>
                  <option value="Kalutara">Kalutara</option>
                  <option value="Kandy">Kandy</option>
                  <option value="Kegalle">Kegalle</option>
                  <option value="Kilinochchi">Kilinochchi</option>
                  <option value="Kurunegala">Kurunegala</option>
                  <option value="Mannar">Mannar</option>
                  <option value="Matale">Matale</option>
                  <option value="Matara">Matara</option>
                  <option value="Monaragala">Monaragala</option>
                  <option value="Mullaitivu">Mullaitivu</option>
                  <option value="NuwaraEliya">Nuwara Eliya</option>
                  <option value="Polonnaruwa">Polonnaruwa</option>
                  <option value="Puttalam">Puttalam</option>
                  <option value="Ratnapura">Ratnapura</option>
                  <option value="Trincomalee">Trincomalee</option>
                  <option value="Vavuniya">Vavuniya</option>
                </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-layer-group"></i>
              <select
                id="classLevel"
                name="classLevel"
                value={formData.classLevel}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select Class Level
                </option>
                <option value="A/L (Local)">A/L (Local)</option>
                <option value="O/L (Local)">O/L (Local)</option>
                <option value="A/L (London)">A/L (London)</option>
                <option value="O/L (London)">O/L (London)</option>
                <option value="Grade 5">Grade 5</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-calendar-alt"></i>
              <input type="date" id="startDate" name="startDate" value={formData.startDate} onChange={handleChange} placeholder="Start Date" />
            </div>

            <div className="formgroup">
              <i className="fas fa-clock"></i>
              <input type="time" id="time" name="time" value={formData.time} onChange={handleChange} placeholder="Time" />
            </div>

            <div className="formgroup">
              <i className="fa fa-money-bill-alt"></i>
              <input type="text" id="classFee" name="classFee" value={formData.classFee} onChange={handleChange} placeholder="Class Fee" />
            </div>

            <div className="formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Drop Class Banner</span>
                <input type="file" accept="image/*" id="file-input" name="cbanner" onChange={handleChange} />
              </label>
            </div>

            <div className="formgroup textarea">
              <textarea name="description" placeholder="Enter the description of the Class..." onChange={handleChange} value={formData.description}></textarea>
            </div>

            <div className="formgroup">
              <i className="fas fa-user"></i>
              <input
                type="text"
                id="teacherName"
                name="teacherName"
                value={formData.teacherName}
                onChange={handleChange}
                placeholder="Teacher Name"
                required
              />
            </div>

            <div className="formgroup">
              <i className="fa fa-graduation-cap"></i>
              <input
                type="text"
                id="teacherQualification"
                name="teacherQualification"
                value={formData.teacherQualification}
                onChange={handleChange}
                placeholder="Teacher Qualification"
                required
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                id="teacherEmail"
                name="teacherEmail"
                value={formData.teacherEmail}
                onChange={handleChange}
                placeholder="Teacher Email Address"
                required
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-phone"></i>
              <input
                  type="tel"
                  id="contact"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  placeholder="Contact No."
                  required
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input
                type="text"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Location"
                required
              />
            </div>

          </div>
          <button type="submit">Create</button>
        </form>
      </div>
    </main>
  );
};

export default AdminCreateTuition;
