import React, { useEffect, useState, useMemo } from 'react';
import '../../assets/css/studyabroad/sacreate.css';
import SADashboard from './SADashboard';
import axios from 'axios';
import countryList from 'react-select-country-list';

const SACreate = () => {
  const institute_id = sessionStorage.getItem('id');

  const [formData, setFormData] = useState({
    country: '',
    university: '',
    courseName: '',
    level: '',
    duration: '',
    startDate: '',
    studyArea: '',
    specification: '',
    careerOpportunities: '',
    industry: '',
    cologo: null,
    codescription: '',
    instituteName: '',
    instituteAddress: '',
    institutePhone: '',
    instituteEmail: '',
    instituteLocation: ''
  });

  
  const [categories, setCategories] = useState([]);
  
  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState('');

  const options = useMemo(() => countryList().getData(), []);



  useEffect(() => {
    const fetchInstituteDetails = async () => {
      try {
        const response = await axios.get(`https://skillbackend.educationesupport.xyz/ins/one/${institute_id}`);
        if (response.data && response.data.length > 0) {
          const institute = response.data[0];
          setFormData(prevData => ({
            ...prevData,
            instituteName: institute.in_name || '',
            instituteAddress: institute.address || '',
            institutePhone: institute.tel || '',
            instituteEmail: institute.email || '',
            instituteLocation: institute.location || ''
          }));
        }
      } catch (error) {
        console.error('Error fetching institute details:', error);
      }
    };
    const fetchCategories = async () => {
      try {
          const response = await fetch('https://skillbackend.educationesupport.xyz/category/all');
          const data = await response.json();
          setCategories(data);
      } catch (error) {
          console.error('Error fetching Categories:', error);
      }
  };

    fetchInstituteDetails();
    fetchCategories();
  }, [institute_id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      setFormData(prevData => ({
        ...prevData,
        [name]: files[0]
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const fetchCategories = async () => {
    try {
        const response = await fetch('https://skillbackend.educationesupport.xyz/category/all');
        const data = await response.json();
        setCategories(data);
    } catch (error) {
        console.error('Error fetching Categories:', error);
    }
};

const handleSubmit = async (e) => {
  e.preventDefault();

  // Check for empty fields or duplicates before submitting
  if (!formData.courseName) {
    alert('Course name is required.');
    return;
  }

  // Example check for duplicates - implement as needed
  const existingCourse = await fetch(`https://skillbackend.educationesupport.xyz/course/check?name=${formData.courseName}`);
  const existingCourseData = await existingCourse.json();
  
  if (existingCourseData.exists) {
    alert('A course with this name already exists.');
    return;
  }

  const formDataToSend = new FormData();

  for (const key in formData) {
    if (formData[key] !== null) {
      formDataToSend.append(key, formData[key]);
    }
  }

  formDataToSend.append('institute_id', institute_id);

  try {
    const response = await fetch('https://skillbackend.educationesupport.xyz/course/create', {
      method: 'POST',
      body: formDataToSend
    });

    const contentType = response.headers.get('Content-Type');
    let data;

    if (contentType && contentType.includes('application/json')) {
      data = await response.json();
    } else {
      data = await response.text();
    }

    if (response.ok) {
      console.log('Form submitted:', data);
      window.alert('Course created successfully!');
      await fetchCategories(); // Refresh categories after creating a course
    } else {
      console.error('Error response:', data);
      window.alert(data.message || 'Failed to create course. Please try again.');
    }
  } catch (error) {
    console.error('Error:', error);
    window.alert('Failed to create course. Please try again.');
  }
};

const addCategory = async () => {
  if (!newCategory) {
    alert('Category name is required');
    return;
  }

  try {
    const response = await axios.post('https://skillbackend.educationesupport.xyz/category/add', {
      category: newCategory.trim(),
    });

    if (response.status === 201) {
      alert('Category added successfully');
      setNewCategory(''); 
      closeModal();
      
      // Fetch updated categories after adding a new category
      await fetchCategories(); // Re-fetch categories after adding a new one
    } else {
      alert('Failed to add category');
    }
  } catch (error) {
    console.error('Error adding category:', error);
    alert(error.response?.data?.error || 'Error adding category'); 
  }
};



const openModal = () => setShowModal(true);
const closeModal = () => setShowModal(false);


  return (
    <main className="study-abroad-create">
      <SADashboard />
      <div className="sa-head-content-text">
        <h4>Study Abroad - Create Course</h4>
      </div>
      <div className="sa-container">
        <form onSubmit={handleSubmit}>
          <div className="new">
            <div className="formgroup">
              <i className="fas fa-globe"></i>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="" disabled>Select Country</option>
                {options.map(option => (
                  <option key={option.value} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-university"></i>
              <input
                type="text"
                id="university"
                name="university"
                value={formData.university}
                onChange={handleChange}
                placeholder="University/Institute/High School"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-book"></i>
              <input
                type="text"
                id="courseName"
                name="courseName"
                value={formData.courseName}
                onChange={handleChange}
                placeholder="Course Name/Degree Name"
              />
            </div>

            <div className="formgroup">
              <i className="fa fa-money-bill-alt"></i>
              <input type="text" id="courseFee" name="courseFee" value={formData.courseFee} onChange={handleChange} placeholder="Course Fee" />
            </div>
            
            <div className="formgroup">
              <i className="fas fa-book-reader"></i>
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                placeholder="Category"
              >
                <option value="" disabled>Select Course Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.category}>
                    {category.category}
                  </option>
                ))}
              </select>

              <button type="button" className="categorybutton" onClick={openModal}>
                Add Category
              </button>

              {/* Category Modal */}
              {showModal && (
                <div className="cmodal">
                  <div className="cmodal-content">
                    <h2>Add New Category</h2>
                    <input
                      type="text"
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                      placeholder="Enter category name"
                    />
                    <div>
                      <button onClick={addCategory}>Add Category</button>
                      <button onClick={closeModal}>Cancel</button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="formgroup">
              <i className="fas fa-layer-group"></i>
              <select
                id="level"
                name="level"
                value={formData.level}
                onChange={handleChange}
              >
                <option value="" disabled>Select Course Level</option>
                <option value="Training">Training</option>
                <option value="Certificate">Certificate</option>
                <option value="Diploma">Diploma</option>
                <option value="Advanced Diploma">Advanced Diploma</option>
                <option value="HND">HND</option>
                <option value="Degree">Degree</option>
                <option value="Postgraduate Diploma">Postgraduate Diploma</option>
                <option value="Master's">Master's</option>
                <option value="PhD">PhD</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-hourglass-start"></i>
              <input
                type="text"
                id="duration"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                placeholder="Duration"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-calendar-alt"></i>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                placeholder="Start Date"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-cogs"></i>
              <input
                type="text"
                id="specification"
                name="specification"
                value={formData.specification}
                onChange={handleChange}
                placeholder="Specification"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-briefcase"></i>
              <input
                type="text"
                id="careerOpportunities"
                name="careerOpportunities"
                value={formData.careerOpportunities}
                onChange={handleChange}
                placeholder="Career Opportunities"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-industry"></i>
              <input
                type="text"
                id="industry"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                placeholder="Industry"
              />
            </div>

            <div className="formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Drop Course Banner</span>
                <input
                  type="file"
                  accept="image/*"
                  id="file-input"
                  name="cologo"
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="formgroup textarea">
              <textarea
                name="codescription"
                placeholder="Enter the description of the Course background........"
                onChange={handleChange}
                value={formData.codescription}
              ></textarea>
            </div>

            {/* Contact Details */}
            <div className="formgroup">
              <h4>Contact Details</h4>
            </div>

            <div className="formgroup">
              <i className="fas fa-building"></i>
              <input
                type="text"
                id="instituteName"
                name="instituteName"
                value={formData.instituteName}
                onChange={handleChange}
                placeholder="Name of Institute"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input
                type="text"
                id="instituteAddress"
                name="instituteAddress"
                value={formData.instituteAddress}
                onChange={handleChange}
                placeholder="Institute Address"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-phone"></i>
              <input
                type="tel"
                id="institutePhone"
                name="institutePhone"
                value={formData.institutePhone}
                onChange={handleChange}
                placeholder="Institute Phone"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                id="instituteEmail"
                name="instituteEmail"
                value={formData.instituteEmail}
                onChange={handleChange}
                placeholder="Institute Email"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input
                type="text"
                id="instituteLocation"
                name="instituteLocation"
                value={formData.instituteLocation}
                onChange={handleChange}
                placeholder="Institute Location"
              />
            </div>

            <div className="formgroup">
              <button type="submit" className="study-abroad-save">
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default SACreate;
