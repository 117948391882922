import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../assets/css/eventview.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';
import axios from 'axios';

const ScholarshipView = () => {
    const { id } = useParams(); 
    const navigate = useNavigate(); 
    const [Scholarships, setScholarships] = useState([]);

    useEffect(() => {
        const fetchScholarshipsData = async () => {
            try {
                const response = await axios.get(`https://skillbackend.educationesupport.xyz/scholarships/${id}`);
                console.log(response.data);
                setScholarships(response.data);
            } catch (error) {
                console.error('Error fetching scholarships data:', error);
            }
        };

        fetchScholarshipsData();
    }, [id]);

    if (!Scholarships) {
        return null; 
    }
    
    const handleContactUs = () => {
        navigate('/contact');
    };

    return (
        <div>
            <SecondNav />
            <div className="event-details">
                <h1>{Scholarships.sProgrammeName}</h1>
                <div className="event-img">
                    {Scholarships.banner && <img src={`https://skillbackend.educationesupport.xyz/Upload/images/scholarships/${Scholarships.banner}`} alt={Scholarships.sProgrammeName} />}
                </div>
                <div className="event-date">
                    <div className="date-box">
                        <div className="date-month">{new Date(Scholarships.closingDate).toLocaleString('default', { month: 'short' })}</div>
                        <div className="date-day">{new Date(Scholarships.closingDate).getDate()}</div>
                    </div>
                    <div className="event-info">
                        <h2>{Scholarships.sProgrammeName}</h2>
                        <p>{Scholarships.country}</p>
                        <p>Hosted by <a href={Scholarships.inWebsite}>{Scholarships.university}</a></p>
                    </div>
                </div>
                <p>{Scholarships.description}</p>
                <h3></h3>
                <ul>
                    {Scholarships.highlights && Scholarships.highlights.map((highlight, index) => (
                        <li key={index}>{highlight}</li>
                    ))}
                </ul>
                <p>Visit <a href={Scholarships.link}>{Scholarships.link}</a></p>
                <h3>Contact Details</h3>
                <p>{Scholarships.institutePhone}</p>
                <p>{Scholarships.instituteEmail}</p>
                <p><a href={Scholarships.inWebsite}>{Scholarships.inWebsite}</a></p>

                <h3>Send Inquiry</h3>
                <button type="cbutton" onClick={handleContactUs}>Contact Us</button>
            </div>
            <Footer />
        </div>
    );
}

export default ScholarshipView;
