import React, { useEffect, useState } from 'react';
import AdminDashboard from './AdminDashboard';
import '../../assets/css/admin/intschlprofile.css'; 
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 

const IntSchlProfile = () => {
    const navigate = useNavigate(); 
    const [isModalOpen, setModalOpen] = useState(true);
    const [schools, setSchools] = useState([]); 
    const [internationalSchool, setInternationalSchool] = useState({
        id: '',
        intschlName: '',
        logo: '',
        commonEmail: '',
        commonPhone: '',
        studyLevel: '',
        description: '',
        facilities: '',
        branchestableRows: [],
        mainLocation: '',
        website: ''
    });

    const BaseUrl = 'https://skillbackend.educationesupport.xyz/internationalSchools';

  
    const fetchSchools = async () => {
        try {
            const response = await axios.get(`${BaseUrl}/all`); 
            setSchools(response.data); 
        } catch (error) {
            console.error('Error fetching schools:', error);
        }
    };

    // Fetch data for a specific school
    const getOneData = async (id) => {
        try {
            const response = await axios.get(`${BaseUrl}/${id}`);
            console.log('API Response:', response.data); 
            setInternationalSchool({
                id: response.data.id,
                intschlName: response.data.intschlName,
                logo: response.data.logo,
                commonEmail: response.data.commonEmail,
                commonPhone: response.data.commonPhone,
                studyLevel: response.data.studyLevel,
                description: response.data.description,
                facilities: response.data.facilities,
                branchestableRows: response.data.branchestableRows || [],
                mainLocation: response.data.mainLocation,
                website: response.data.website
            });
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    };
    

    useEffect(() => {
        fetchSchools(); 
    }, []);

    // Handle school selection
    const handleSchoolClick = (schoolId) => {
        sessionStorage.setItem('id', schoolId);
        getOneData(schoolId); 
        setModalOpen(false); 
    };

    const closeModal = () => setModalOpen(false);

    return (
        <div>
            <AdminDashboard />

            {isModalOpen ? (
                <div className="modal modal-open">
                    <div className="modal-content">
                        <h3>Select an International School</h3>
                        {/* List of all registered schools from the database */}
                        <ul className="school-list">
                            {schools.length > 0 ? (
                                schools.map((school) => (
                                    <li key={school.id} onClick={() => handleSchoolClick(school.id)}>
                                        {school.intschlName}
                                    </li>
                                ))
                            ) : (
                                <p>No schools registered yet.</p>
                            )}
                        </ul>
                        <button onClick={closeModal}>Cancel</button>
                    </div>
                </div>
            ) : (
                <div className="intschl-container">
                    <div className="intschl-profile-edit">
                        <button>
                            <a href={`/editintschlprofile/${internationalSchool.id}`}>Edit profile <i className="fa-regular fa-pen-to-square"></i></a>
                        </button>
                    </div>
                    <div className="intschl-admin-box1">
                        <div className="intschl-box-profile">
                            <div className="heading-text-inview">
                                <h3>{internationalSchool.intschlName}</h3>
                                <hr id="hr-inview" />
                            </div>
                            <div className="img-inview">
                                <img src={`https://skillbackend.educationesupport.xyz/Upload/Images/intschls/${internationalSchool.logo}`} alt="" width={300} />
                            </div>
                            <div className="intschl-content-inview">
                                <p>{internationalSchool.description}</p>
                            </div>
                            <div className="branch-info">
                                <h4>Branches</h4>
                                {internationalSchool.branchestableRows.length > 0 ? (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>City</th>
                                                <th>Address</th>
                                                <th>Phone</th>
                                                <th>Location</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {internationalSchool.branchestableRows.map((row, index) => (
                                                <tr key={index}>
                                                    <td>{row.city}</td>
                                                    <td>{row.address}</td>
                                                    <td>{row.phone}</td>
                                                    <td>
                                                        {row.location ? (
                                                            <a
                                                                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(row.location)}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                View Map
                                                            </a>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </td>

                                                    <td>{row.email || 'N/A'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p>No branch information available.</p>
                                )}
                            </div>
                        </div>
                        <div className="intschl-box-profile">
                            <div className="block-two-inview">
                                <ul>
                                    <li><strong><i className="fa-solid fa-globe"></i> Website </strong> <a href={internationalSchool.website} target="_blank" rel="noopener noreferrer">
                                        {internationalSchool.website}</a>
                                    </li>
                                    <li><strong><i className="fa-solid fa-envelope"></i> Email </strong> <p>{internationalSchool.commonEmail}</p></li>
                                    <li><strong><i className="fa-solid fa-phone"></i> Contact </strong> <p>{internationalSchool.commonPhone}</p></li>
                                    <li><strong><i className="fa-solid fa-graduation-cap"></i> Study Level </strong> <p>{internationalSchool.studyLevel}</p></li>
                                    <li><strong><i className="fa-solid fa-building"></i> Facilities </strong> <p>{internationalSchool.facilities}</p></li>
                                </ul>
                            </div>
                            

                        </div>
                        
                    </div>
                    <div className="map-section">
                                <iframe src={internationalSchool.mainLocation} allowFullScreen title="Map"></iframe>
                            </div>
                </div>
            )}
        </div>
    );
};

export default IntSchlProfile;
