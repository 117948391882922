import React, { useEffect, useState } from 'react';
import InstituteDashboard from './InstituteDashboard';
import '../../assets/css/Institute/CreateCourse.css';
import axios from 'axios';

const CreateEvent = () => {
  const institute_id = sessionStorage.getItem('id');

  const [formData, setFormData] = useState({
    university: '',
    universityType: '',
    eventName: '',
    venue: '',
    date: '',
    time: '',
    link: '',
    banner: null,
    description: '',
    inWebsite: '',
    instituteAddress: '',
    institutePhone: '',
    instituteEmail: '',    
  });

  useEffect(() => {
    const fetchInstituteDetails = async () => {
      try {
        const response = await axios.get(`https://skillbackend.educationesupport.xyz/ins/one/${institute_id}`);
        const institute = response.data[0];
        setFormData(prevData => ({
          ...prevData,
          university: institute.in_name || '',
          instituteAddress: institute.address || '',
          institutePhone: institute.tel || '',
          instituteEmail: institute.email || '',
          universityType: institute.type || '',
          inWebsite: institute.website || '',
        }));
      } catch (error) {
        console.error('Error fetching institute details:', error);
      }
    };
    fetchInstituteDetails();
  }, [institute_id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      setFormData(prevData => ({
        ...prevData,
        [name]: files[0]
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
  
    for (const key in formData) {
      if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    }
  
    formDataToSend.append('institute_id', institute_id);
  
    try {
      const response = await fetch('https://skillbackend.educationesupport.xyz/events/create', {
        method: 'POST',
        body: formDataToSend
      });
  
      const contentType = response.headers.get('Content-Type');
      let data;
  
      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else {
        data = await response.text();
      }
  
      if (response.ok) {
        console.log('Form submitted:', data);
        window.alert('Event created successfully!');
      } else {
        console.error('Error response:', data);
        window.alert(data.message || 'Failed to create event. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      window.alert('Failed to create event. Please try again.');
    }
  };

  return (
    <main className='institute-create-course'>
      <InstituteDashboard />
      <div className="head-content-text">
        <h4>ADD EVENT</h4>
      </div>
      <div className="sa-container">
        <form onSubmit={handleSubmit}>
          <div className="new">

            <div className="formgroup">
              <i className="fas fa-building"></i>
              <input type="text" id="university" name="university" value={formData.university} onChange={handleChange} placeholder="University / Institute Name" />
            </div>

            <div className="formgroup">
              <i className="fas fa-university"></i>
              <input type="text" id="universityType" name="universityType" value={formData.universityType} onChange={handleChange} placeholder="University / Institute Type" />
            </div>

            <div className="formgroup">
              <i className="fas fa-book"></i>
              <input type="text" id="eventName" name="eventName" value={formData.eventName} onChange={handleChange} placeholder="Event Name" />
            </div>

            <div className="formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input type="text" id="venue" name="venue" value={formData.venue} onChange={handleChange} placeholder="Venue" />
            </div>

            <div className="formgroup">
              <i className="fas fa-calendar-alt"></i>
              <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} />
            </div>

            <div className="formgroup">
              <i className="fas fa-clock"></i>
              <input type="text" id="time" name="time" value={formData.time} onChange={handleChange} placeholder="Time" />
            </div>

            <div className="formgroup">
              <i className="fas fa-globe"></i>
              <input type="url" placeholder="Link" name="link" id="link" value={formData.link} onChange={handleChange} />
            </div>

            <div className="formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Drop Event Banner</span>
                <input type="file" accept="image/*" id="file-input" name="banner" onChange={handleChange} />
              </label>
            </div>

            <div className="formgroup textarea">
              <textarea name="description" placeholder="Enter the description of the Event..." onChange={handleChange} value={formData.description} />
            </div>

            <div className="formgroup">
              <h4>Contact Details</h4>
            </div>

            <div className="formgroup">
              <i className="fas fa-globe"></i>
              <input type="url" placeholder="Institute Website" name="inWebsite" id="inWebsite" value={formData.inWebsite} onChange={handleChange} />
            </div>

            <div className="formgroup">
              <i className="fas fa-address-card"></i>
              <input type="text" id="instituteAddress" name="instituteAddress" value={formData.instituteAddress} onChange={handleChange} placeholder="Institute Address" />
            </div>

            <div className="formgroup">
              <i className="fas fa-phone"></i>
              <input type="tel" id="institutePhone" name="institutePhone" value={formData.institutePhone} onChange={handleChange} placeholder="Institute Phone" />
            </div>

            <div className="formgroup">
              <i className="fas fa-envelope"></i>
              <input type="email" id="instituteEmail" name="instituteEmail" value={formData.instituteEmail} onChange={handleChange} placeholder="Institute Email" />
            </div>

            <button type="submit">CREATE</button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default CreateEvent;
