import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard';
import '../../assets/css/admin/allcourses.css';

const PendingApplications = () => {
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        fetchApplications();
    }, []);

    const fetchApplications = async () => {
        try {
            const response = await fetch('https://skillbackend.educationesupport.xyz/applications/pending');
            const data = await response.json();
            setApplications(data);
        } catch (error) {
            console.error('Error fetching applications:', error);
        }
    };

    const handleDelete = async (applicationId) => { 
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/applications/delete/${applicationId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setApplications(applications.filter(application => application.id !== applicationId));
                window.alert('Application deleted');
            } else {
                console.error('Failed to delete application');
            }
        } catch (error) {
            console.error('Error deleting application:', error);
        }
    };

    const handleApprove = async (applicationId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/applications/approve/${applicationId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setApplications(applications.filter(application => application.id !== applicationId));
                window.alert('Application Approved');
            } else {
                console.error('Failed to approve application');
            }
        } catch (error) {
            console.error('Error approving application:', error);
        }
    };

    return (
        <div>
            <AdminDashboard />
            <div className='AllViews-admin-course'>
                <div className="search-icon-admin admin-course-search">
                    <h1>PENDING APPLICATIONS</h1>
                    <form>
                        <input type="text" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                </div>
                <div className="scroller-box">
                    <div className="table-course">
                        <table>
                            <thead>
                                <tr>
                                    <th>Application Name</th>
                                    <th>Owner Name</th>
                                    <th>Owner Email</th>
                                    <th>Owner Contact</th>
                                    <th>Link</th>
                                    <th>PDF Application</th>
                                    <th>Expire Time</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applications.map((application) => (
                                    <tr key={application.id}>
                                        <td>{application.appName}</td>
                                        <td>{application.ownerName}</td>
                                        <td>{application.ownerEmail}</td>
                                        <td>{application.contact}</td>
                                        <td>
                                            <a href={application.link} target="_blank" rel="noopener noreferrer">
                                                View Link
                                            </a>
                                        </td>
                                        <td>
                                            <a href={`https://skillbackend.educationesupport.xyz/Upload/pdf/${application.application}`} target="_blank" rel="noopener noreferrer">
                                                View PDF
                                            </a>
                                        </td>
                                        <td>{new Date(application.expire_time).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true }).replace(',', ' -')}</td>
                                        <td><a href="#!" id='view-website'>WEBSITE</a></td>
                                        <td id='action-buttons'>
                                            <a href="#!" id='app-details' onClick={() => handleApprove(application.id)}>APPROVE</a>
                                            <a href="#!" id='del-details' onClick={() => handleDelete(application.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PendingApplications;
