import React, { useState } from 'react'
import '../../assets/css/Gofficer/GC.css'
import axios from 'axios';
import uniqid from 'uniqid';
import Swal from 'sweetalert2';
import AdminDashboard from '../Admin/AdminDashboard';



const GCreate = () => {


    const [GC, setGC] = useState({
        name: '',
        inlogo: '',
        email: '',
        tel: '',
        district: '',
    })

    const handleChange = (e) => {
        e.preventDefault();

        setGC({
            ...GC,
            [e.target.name]: e.target.name === 'inlogo' ? e.target.files[0] : e.target.value
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(GC)

        const formdata = new FormData();

        formdata.append('id', uniqid())
        formdata.append('name', GC.name)
        formdata.append('email', GC.email)
        formdata.append('tel', GC.tel)
        formdata.append('district', GC.district)
        formdata.append('img', GC.inlogo)
        formdata.append('pass', uniqid('pass'))

        console.log(formdata)


        axios.post('https://skillbackend.educationesupport.xyz/govofficer/register', formdata).then((result) => {

            Swal.fire({
                icon: 'success',
                title: 'Government officer added successfully',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                window.location.href = '/allgc';
            })
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Something went wrong',
                showConfirmButton: false,
                timer: 1500

            })
        })




    }







    return (
        <>

            <AdminDashboard />

            <div id='GCreate'>

                <div className="form-content">


                    <form onSubmit={handleSubmit}>

                        <h1>ADD GOVERNMENT OFFICER</h1>

                        <div className="form-group-1">
                            <label for="file-input" class="drop-container">
                                <span class="drop-title">Government officer Profile</span>
                                <input type="file" accept="image/*" required="" id="file-input" name='inlogo' onChange={handleChange} />
                            </label>
                        </div>

                        <div className="form-group-1">
                            <i class="fa-regular fa-user"></i>
                            <input type="text" placeholder='Government officer Name' name='name' onChange={handleChange} />
                        </div>

                        <div className="form-group-1">
                            <i class="fa-regular fa-envelope"></i>
                            <input type="email" placeholder='Government officer Email' name='email' onChange={handleChange} />
                        </div>

                        <div className="form-group-1">
                            <i class="fa-regular fa-address-book"></i>
                            <input type="number" placeholder='Government officer Contact' name='tel' onChange={handleChange} />
                        </div>



                        <div className="form-group-1">
                            <i class="fa-solid fa-earth-americas"></i>
                            <select name="district" placeholder="Category" onChange={handleChange}>
                                <option value="" disabled selected style={{ padding: '5px', fontSize: '1rem' }}>
                                    Select District
                                </option>
                                <option value="Colombo" style={{ padding: '5px', fontSize: '1rem' }}>Colombo</option>
                                <option value="Gampaha" style={{ padding: '5px', fontSize: '1rem' }}>Gampaha</option>
                                <option value="Kalutara" style={{ padding: '5px', fontSize: '1rem' }}>Kalutara</option>
                                <option value="Kandy" style={{ padding: '5px', fontSize: '1rem' }}>Kandy</option>
                                <option value="Matale" style={{ padding: '5px', fontSize: '1rem' }}>Matale</option>
                                <option value="Nuwara Eliya" style={{ padding: '5px', fontSize: '1rem' }}>Nuwara Eliya</option>
                                <option value="Galle" style={{ padding: '5px', fontSize: '1rem' }}>Galle</option>
                                <option value="Matara" style={{ padding: '5px', fontSize: '1rem' }}>Matara</option>
                                <option value="Hambantota" style={{ padding: '5px', fontSize: '1rem' }}>Hambantota</option>
                                <option value="Jaffna" style={{ padding: '5px', fontSize: '1rem' }}>Jaffna</option>
                                <option value="Kilinochchi" style={{ padding: '5px', fontSize: '1rem' }}>Kilinochchi</option>
                                <option value="Mannar" style={{ padding: '5px', fontSize: '1rem' }}>Mannar</option>
                                <option value="Vavuniya" style={{ padding: '5px', fontSize: '1rem' }}>Vavuniya</option>
                                <option value="Mullaitivu" style={{ padding: '5px', fontSize: '1rem' }}>Mullaitivu</option>
                                <option value="Batticaloa" style={{ padding: '5px', fontSize: '1rem' }}>Batticaloa</option>
                                <option value="Ampara" style={{ padding: '5px', fontSize: '1rem' }}>Ampara</option>
                                <option value="Trincomalee" style={{ padding: '5px', fontSize: '1rem' }}>Trincomalee</option>
                                <option value="Kurunegala" style={{ padding: '5px', fontSize: '1rem' }}>Kurunegala</option>
                                <option value="Puttalam" style={{ padding: '5px', fontSize: '1rem' }}>Puttalam</option>
                                <option value="Anuradhapura" style={{ padding: '5px', fontSize: '1rem' }}>Anuradhapura</option>
                                <option value="Polonnaruwa" style={{ padding: '5px', fontSize: '1rem' }}>Polonnaruwa</option>
                                <option value="Badulla" style={{ padding: '5px', fontSize: '1rem' }}>Badulla</option>
                                <option value="Monaragala" style={{ padding: '5px', fontSize: '1rem' }}>Monaragala</option>
                                <option value="Ratnapura" style={{ padding: '5px', fontSize: '1rem' }}>Ratnapura</option>
                                <option value="Kegalle" style={{ padding: '5px', fontSize: '1rem' }}>Kegalle</option>
                            </select>

                        </div>

                        <div className="form-group-1">

                            <input type="submit" name='submit' value="Register" />
                        </div>
                    </form>
                </div>


            </div></>
    )
}

export default GCreate