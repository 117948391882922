import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import InstituteDashboard from './InstituteDashboard';
import '../../assets/css/Institute/CreateCourse.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import countryList from 'react-select-country-list';

const CreateScholarship = () => {
    const institute_id = sessionStorage.getItem('id');

    const [formData, setFormData] = useState({
      sProgrammeName: '',
      country: '',
      banner: null,
      university: '',
      closingDate: '',
      description: '',
      instituteWebsite: '',
      institutePhone: '',
      instituteEmail: '',
    });
  
    const navigate = useNavigate();

    const options = useMemo(() => countryList().getData(), []);
  
    useEffect(() => {
      const fetchInstituteDetails = async () => {
        try {
          const response = await axios.get(`https://skillbackend.educationesupport.xyz/ins/one/${institute_id}`);
          const institute = response.data[0];
          setFormData(prevData => ({
            ...prevData,
            university: institute.in_name || '',
            institutePhone: institute.tel || '',
            instituteAddress: institute.address || '',
            instituteEmail: institute.email || '',
            instituteWebsite: institute.website || '',
          }));
        } catch (error) {
          console.error('Error fetching institute details:', error);
        }
      };
      if (institute_id) {
        fetchInstituteDetails();
      } else {
        console.error('No institute ID found in session storage.');
      }
    }, [institute_id]);
  
    const handleChange = (e) => {
      const { name, value, type, files } = e.target;
      if (type === 'file') {
        setFormData(prevData => ({
          ...prevData,
          [name]: files[0]
        }));
      } else {
        setFormData(prevData => ({
          ...prevData,
          [name]: value
        }));
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }
  
      if (institute_id) {
        formDataToSend.append('institute_id', institute_id); 
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'No Institute ID',
          text: 'Institute ID is missing. Please try again.',
          showConfirmButton: true,
        });
        return;
      }
  
      fetch('https://skillbackend.educationesupport.xyz/scholarships/create', {
        method: 'POST',
        body: formDataToSend,
      })
      .then((response) => {
        if (response.status === 400) {
          return response.text().then((message) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Oops...',
              text: message,
              showConfirmButton: true,
            });
          });
        } else if (response.ok) {
          return response.text().then((data) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Scholarship Added Successfully',
              text: 'Your scholarship has been added successfully.',
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              navigate('/manscholarship');
            });
          });
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: true,
        });
      });
    };
  
  return (
    <main className='institute-create-course'>
      <InstituteDashboard />
      <div className="head-content-text">
        <h4>ADD Scholarships & Offers</h4>
      </div>
      <div className="r_container">
        <form onSubmit={handleSubmit}>
          <div className="new">

            <div className="formgroup">
              <i className="fas fa-book"></i>
              <input
                type="text"
                id="sProgrammeName"
                name="sProgrammeName"
                value={formData.sProgrammeName}
                onChange={handleChange}
                placeholder="Scholarship Programme Name"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-globe"></i>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                {options.map(option => (
                  <option key={option.value} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Drop Banner</span>
                <input type="file" accept="image/*" id="file-input" name="banner" onChange={handleChange} />
              </label>
            </div>


            <div className="formgroup">
              <i className="fas fa-building"></i>
              <input
                type="text"
                id="university"
                name="university"
                value={formData.university}
                onChange={handleChange}
                placeholder="Offered By (University / Institute Name)"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-calendar-alt"></i>
              <input
                type="date"
                id="closingDate"
                name="closingDate"
                value={formData.closingDate}
                onChange={handleChange}
                placeholder="Valid till (Closing Date)"
              />
            </div>

            <div className="formgroup textarea">
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Scholarship Description"
              ></textarea>
            </div>

            <div className="formgroup">
              <i className="fas fa-link"></i>
              <input
                type="text"
                id="instituteWebsite"
                name="instituteWebsite"
                value={formData.instituteWebsite}
                onChange={handleChange}
                placeholder="Institute Address"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-phone"></i>
              <input
                type="text"
                id="institutePhone"
                name="institutePhone"
                value={formData.institutePhone}
                onChange={handleChange}
                placeholder="Institute Phone"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-envelope"></i>
              <input
                type="text"
                id="instituteEmail"
                name="instituteEmail"
                value={formData.instituteEmail}
                onChange={handleChange}
                placeholder="Institute Email"
              />
            </div>

          </div>
          <button type="submit">Add</button>
        </form>
      </div>
    </main>
  );
};

export default CreateScholarship;
