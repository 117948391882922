import React, { useEffect, useState } from 'react'
import Img from '../../assets/images/skillslogo.png'
import '../../assets/css/studyabroad/sadashboard.css'
import axios from 'axios';
import Swal from 'sweetalert2'


const SADashboard = () => {
    if (!sessionStorage.getItem('id')) {
        window.location.href = '/rlogin'
    }
 
    const BaseUrl = 'https://skillbackend.educationesupport.xyz/ins/'

    const [formData, setFormData] = useState({
        inname: '',
        inWebsite: '',
        inEmail: '',
        inPhone: '',
        inaddress: '',
        inlocation: '',
        inlogo: '',
        indescription: '',
        firstlog: '',
        country: '',
        type: ''
    });

    const id = sessionStorage.getItem('id');

    const getOneData = async () => {
        axios.get(`${BaseUrl}/one/${id}`).then((response) => {
            setFormData({
                inname: response.data[0].in_name,
                inEmail: response.data[0].email,
                inWebsite: response.data[0].website,
                inPhone: response.data[0].tel,
                inaddress: response.data[0].address,
                inlocation: response.data[0].token,
                inlogo: response.data[0].img,
                indescription: response.data[0].desc_,
                firstlog: response.data[0].firstlog,
                country: response.data[0].country,
                type: response.data[0].type
            })
            console.log(response.data)
        }).catch((err) => {
            console.log(err)
        })
    }
   
    useEffect(() => {

        getOneData()
    }, [id])

    const [isOpen, setOpen] = useState('side-nav-admin');

    const hadleOpen = (e) => {
        e.preventDefault();
        isOpen === 'side-nav-admin' ? setOpen('side-nav-admin side-open') : setOpen('side-nav-admin')
    }

    const logOut = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        window.location.href = '/rlogin'
    }


    const popUp = () => {

        if (formData.firstlog === 'false') {

            Swal.fire({
                title: "Change Password",
                text: "You must change the password to your own password!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Change it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/changepass'
                }
            });
        }

    }

    popUp();

  return (


    <div id='sa-dashboard'>

    <div className="nav-bar-admin">


        {/* logo section */}
        <div className="logo-section logo-section-admin">
            <a href="/">  <img src={Img} alt="logo.png" width={150} /></a>

            <div className="menu" onClick={hadleOpen}>
                <span className='menu-logo-admin'>menu <i className='fa-solid fa-bars'></i> </span>
            </div>
        </div>



        <div className="menu-list-log-in-admin">
            <ul>
                <li>
                    <div className="solid-icon-admin-profile">
                         <a href="">
                            <h4 style={{ color: "white" }}> {formData.inname}</h4>
                        </a>
                    </div>
                </li>

                <li>
                    <div className="solid-icon-admin-logout">

                        <a onClick={logOut}>
                            <i class="fa-solid fa-sign-out"></i>
                        </a>
                    </div>
                </li>
            </ul>

        </div>

    </div>


    <div className={isOpen}>

        <div className="menu-list-sa">
            <ul>
                <li>   <h4 className='text-admin-head'>STUDY ABRAOD MENU</h4></li>
                <hr />
                <li>
                    <div className="solid-icon-admin">
                        <a href="/saprofile">
                            <i class="fa-solid fa-house"></i>
                            Profile View
                        </a>
                    </div>

                </li>

                <li id='drop-admin-1'>
                    <a>
                        <span className='solid-icon-admin'>
                            <i class="fa-solid fa-building"></i>
                            Course
                            <i class="fa-solid fa-sort-down"></i>
                        </span>

                        <div className="dropdown-content4-admin">
                            <a href="/sacourses">Create course</a>
                            <a href="/samanagecourses">Manage Course</a>
                            <a href="/sadelcourses">Delete Course</a>
                        </div>

                    </a>
                </li>

                <li>
                    <div className="solid-icon-admin">
                        <a href="/saprofile">
                            <i class="fas fa-graduation-cap"></i>
                            SCHOLARSHIPS
                        </a>
                    </div>

                </li>
            </ul>
        </div>
    </div>
</div>
  )
}

export default SADashboard