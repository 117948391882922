import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard';
import '../../assets/css/admin/allcourses.css';

const PendingLangQual = () => {
    const [langQualifications, setLangQualifications] = useState([]);

    useEffect(() => {
        fetchLangQualifications();
    }, []);

    const fetchLangQualifications = async () => {
        try {
            const response = await fetch('https://skillbackend.educationesupport.xyz/langQualifications/pending');
            const data = await response.json();
            setLangQualifications(data);
        } catch (error) {
            console.error('Error fetching Language Qualifications:', error);
        }
    };

    const handleDelete = async (langQualificationsId) => { 
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/langQualifications/delete/${langQualificationsId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setLangQualifications(langQualifications.filter(langQualifications => langQualifications.id !== langQualificationsId));
                window.alert('Language Qualification deleted');
            } else {
                console.error('Failed to delete Language Qualification');
            }
        } catch (error) {
            console.error('Error deleting Language Qualification:', error);
        }
    };

    const handleApprove = async (langQualificationsId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/langQualifications/approve/${langQualificationsId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setLangQualifications(langQualifications.filter(langQualifications => langQualifications.id !== langQualificationsId));
                window.alert('Language Qualification Approved');
            } else {
                console.error('Failed to approve Language Qualification');
            }
        } catch (error) {
            console.error('Error approving Language Qualification:', error);
        }
    };

    return (
        <div>
            <AdminDashboard />
            <div className='AllViews-admin-course'>
                <div className="search-icon-admin admin-course-search">
                    <h1>PENDING LANGUAGE QUALIFICATIONS</h1>
                    <form>
                        <input type="text" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                </div>
                <div className="scroller-box">
                    <div className="table-course">
                        <table>
                            <thead>
                                <tr>
                                    <th>Language Exam Name</th>
                                    <th>Institute Name</th>
                                    <th>Teaching Method</th>
                                    <th>Duration</th>
                                    <th>Course Fee</th>
                                    <th>Paying Method</th>
                                    <th>Contact No.</th>
                                    <th>Email</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {langQualifications.map((langQualifications) => (
                                    <tr key={langQualifications.id}>
                                        <td>{langQualifications.langQualName}</td>
                                        <td>{langQualifications.university}</td>              
                                        <td>{langQualifications.teachingMethod}</td>
                                        <td>{langQualifications.duration}</td>
                                        <td>{langQualifications.courseFee}</td>              
                                        <td>{langQualifications.payMethod}</td>
                                        <td>{langQualifications.institutePhone}</td>
                                        <td>{langQualifications.instituteEmail}</td>
                                        <td>
                                            <a href={langQualifications.instituteWebsite} target="_blank" rel="noopener noreferrer">
                                                Visit Website
                                            </a>
                                        </td>
                                        <td id='action-buttons'>
                                            <a href="#!" id='app-details' onClick={() => handleApprove(langQualifications.id)}>APPROVE</a>
                                            <a href="#!" id='del-details' onClick={() => handleDelete(langQualifications.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PendingLangQual;
