import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../assets/css/eventview.css';
import '../assets/css/courseView.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';
import axios from 'axios';

const ProfessionalExamView = () => {
    const { id } = useParams(); 
    const navigate = useNavigate(); 
    const [exam, setExam] = useState(null);

    useEffect(() => {
        const fetchExamData = async () => {
            try {
                const response = await axios.get(`https://skillbackend.educationesupport.xyz/professionalExams/${id}`);
                console.log(response.data);
                setExam(response.data);
            } catch (error) {
                console.error('Error fetching professional exam data:', error);
            }
        };

        fetchExamData();
    }, [id]);

    if (!exam) {
        return null; 
    }
    
    const handleContactUs = () => {
        navigate('/contact');
    };

    return (
        <div>
            <SecondNav />
            <div className="c_container">
                <div className="c_leftcolumn">
                    <div className="programme">
                        <h2>{exam.examName} ({exam.shortName})</h2>
                        <hr id="hr-programme" />
                        <div className="c-image">
                            {exam.logo && <img src={`https://skillbackend.educationesupport.xyz/Upload/images/profExams/${exam.logo}`} alt={exam.examName} />}
                        </div>
                        <h3>Overview</h3>
                        <p id="p-overview">{exam.description || 'No description available'}</p>
                        
                        <div className="examination-structure">
                            <h3>Examination Structure</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Level</th>
                                        <th>Qualification</th>
                                        <th>Duration</th>
                                        <th>Start Date</th>
                                        <th>Professional Skills</th>
                                        <th>Exam Fee</th>
                                        <th>Online Apply Now</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {exam.tableRows && exam.tableRows.length > 0 ? (
                                       exam.tableRows.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.level}</td>
                                                <td>{row.qualification}</td>
                                                <td>{row.duration}</td>
                                                <td>{row.startDate}</td>
                                                <td>{row.professionalSkills}</td>
                                                <td>{row.examFee}</td>
                                                <td><a href={row.onlineApplyNow} target="_blank" rel="noopener noreferrer">Apply Now</a></td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7">No examination structure available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        
                        <h3>Contact Information</h3>
                        <p><strong>Address:</strong> {exam.instituteAddress}</p>
                        <p><strong>Contact No.:</strong> {exam.institutePhone}</p>
                        <p><strong>Email:</strong> <a href={`mailto:${exam.instituteEmail}`}>{exam.instituteEmail}</a></p>
                        <p><strong>Website:</strong> <a href={exam.instituteWebsite} target="_blank" rel="noopener noreferrer">{exam.instituteWebsite}</a></p>

                        <h3>Send Inquiry</h3>
                        <button type="cbutton" onClick={handleContactUs}>Contact Us</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ProfessionalExamView;
