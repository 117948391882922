import React, { useState, useEffect } from 'react';
import InstituteDashboard from './InstituteDashboard';

const DelStdLoansIns = () => {
    const [stdLoan, setStdLoan] = useState([]);

    useEffect(() => {
        fetchDeletedStdLoan();
    }, []);

    const fetchDeletedStdLoan = async () => {
        try {
            const response = await fetch('https://skillbackend.educationesupport.xyz/stdLoans/deleted');
            const data = await response.json();
            setStdLoan(data);
        } catch (error) {
            console.error('Error fetching deleted Student Loans:', error);
        }
    };

    const handleRestore = async (stdLoanId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/stdLoans/restore/${stdLoanId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setStdLoan(stdLoan.filter(stdLoan => stdLoan.id !== stdLoanId));
                window.alert('Student Loan Restored');
            } else {
                console.error('Failed to restore Student Loan');
            }
        } catch (error) {
            console.error('Error restoring Student Loan:', error);
        }
    };

    const handlePermanentDelete = async (stdLoanId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/stdLoans/delete/permanent/${stdLoanId}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setStdLoan(stdLoan.filter(stdLoan => stdLoan.id !== stdLoanId));
                window.alert('Student Loan delete permanently');
            } else {
                console.error('Failed to delete Student Loan permanently');
            }
        } catch (error) {
            console.error('Error deleting Student Loan permanently:', error);
        }
    };


    return (
        <div>
            <InstituteDashboard />
            <div className='AllViews-admin-course'>
                <div className="search-icon-admin admin-course-search">
                    <h1>DELETE STUDENTS LOANS</h1>
                    <form>
                        <input type="text" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                </div>

                <div className="scroller-box">
                <div className="table-course">
                    <table>
                            <thead>
                                <tr>
                                <th>Degree Name</th>
                                    <th>Institute Name</th>
                                    <th>Start Date</th>
                                    <th>Duration</th>
                                    <th>Contact No.</th>
                                    <th>Email</th>
                                    <th>Location</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stdLoan.map((StdLoan) => (
                                    <tr key={StdLoan.id}>
                                        <td>{StdLoan.degreeName}</td>
                                        <td>{StdLoan.university}</td>
                                        <td>{new Date(StdLoan.startDate).toLocaleDateString('en-CA')}</td>
                                        <td>{StdLoan.duration}</td>
                                        <td>{StdLoan.institutePhone}</td>
                                        <td>{StdLoan.instituteEmail}</td>
                                        <td>{StdLoan.instituteLocation}</td>
                                        <td>
                                            <a href="#!" id='view-website'>WEBSITE</a>
                                        </td>
                                        <td id='action-buttons'>
                                            <a href="#!" id='restore-details' onClick={() => handleRestore(stdLoan.id)}>RESTORE</a>
                                            <a href="#!" id='del-details' onClick={() => handlePermanentDelete(stdLoan.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default DelStdLoansIns;
