import React, { useEffect, useState } from 'react'
import axios from 'axios'
import AdminDashboard from './AdminDashboard'
import Swal from 'sweetalert2'

const Localjoba = () => {

    const [localjobs, setLocaljobs] = useState([])

    const getAll = () => {
        axios.get('https://skillbackend.educationesupport.xyz/ljobs/getapprove').then((response) => {
            setLocaljobs(response.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        getAll();


    }, [])



    const [searchTerm, setSearchTerm] = useState('')

    const handleSearch = (e) => {
        e.preventDefault()
        setSearchTerm(e.target.value)
    }

    const filteredInstitutes = localjobs.filter((institute) =>
        institute.jobtit.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const UpdateDelete = (e, id) => {

        e.preventDefault();
        console.log(id);

        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to delete this?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('https://skillbackend.educationesupport.xyz/ljobs/updateapprove' , { id: id, approve: "false" }).then((response) => {

                    getAll()


                }).catch((err) => {
                    console.log(err);
                })
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                });
            }
        });



    }

    const UpdateRestore = (e, id) => { }




    console.log(localjobs);
    return (
        <div>

            <AdminDashboard />

            <div className='AllViews-admin'>

                <div className="search-icon-admin admin-institute-search">

                    <h1>ALL LOCAL JOBS</h1>
                    <div>
                        <button id="del-details" style={{ width: "auto", marginBottom: "10px", padding: "8px", backgroundColor: "blue", color: "white" }} > <a href="/localjobapen" style={{ color: "white" }}>PENDING JOBS</a> </button>
                        <form >
                            <input type="text" onChange={handleSearch} id="" placeholder='Search Item' />
                            <input type="submit" value="SEARCH" />
                        </form>
                    </div>
                </div>

                <div className="scroller-box">
                    <div className="table-institute">
                        <table>
                            <thead>
                                <tr>
                                    <th>Company Name</th>
                                    <th>Email</th>
                                    <th>End Date</th>
                                    <th>Tel no</th>
                                    <th>Job Title</th>
                                    <th>Job Level</th>
                                    <th>Job Type</th>
                                    <th>View</th>
                                    <th>Action</th>

                                </tr>
                            </thead>

                            <tbody>


                                {filteredInstitutes.length > 0 ? (
                                    filteredInstitutes.map((insti) => (
                                        <tr key={insti.id}>
                                            <td>{insti.cname}</td>
                                            <td>{insti.email}</td>
                                            <td>{insti.cdate}</td>
                                            <td>{insti.contact}</td>
                                            <td>{insti.jobtit}</td>
                                            <td>{insti.joblevel}</td>
                                            <td>{insti.jobtype}</td>
                                            <td>
                                                <button id="view-web">
                                                    <a href={`/localjview/${insti.id}`}>SITE VIEW</a>
                                                </button>
                                            </td>
                                            <td id="action-buttons">
                                                <button
                                                    onClick={(e) => {
                                                        UpdateDelete(e, insti.id)
                                                    }}
                                                    id="app-details" style={{backgroundColor:"red"}}
                                                >
                                                    REMOVE
                                                </button>
                                            </td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" style={{ textAlign: 'center', padding: '10px' }}>
                                            Search data not found
                                        </td>
                                    </tr>
                                )}





                            </tbody>
                        </table>

                    </div>

                </div>

            </div>



        </div>

    )
}

export default Localjoba