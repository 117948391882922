import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InstituteDashboard from './InstituteDashboard';
import '../../assets/css/Institute/createprofexams.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const CreateProfExams = () => {
  const institute_id = sessionStorage.getItem('id');

  const [formData, setFormData] = useState({
    logo: null,
    examName: '',
    shortName: '',
    description: '', 
    tableRows: [{
      level: '',
      qualification: '',
      duration: '',
      startDate: '',
      professionalSkills: '',
      examFee: '',
      onlineApplyNow: '',
    }],
    university: '',
    instituteAddress: '',
    institutePhone: '',
    instituteEmail: '',
    instituteWebsite: '',
    
    
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInstituteDetails = async () => {
      try {
        const response = await axios.get(`https://skillbackend.educationesupport.xyz/ins/one/${institute_id}`);
        const institute = response.data[0];
        setFormData(prevData => ({
          ...prevData,
          university: institute.in_name || '',
          instituteAddress: institute.address || '',
          institutePhone: institute.tel || '',
          instituteEmail: institute.email || '',
          instituteWebsite: institute.website || '',
        }));
      } catch (error) {
        console.error('Error fetching institute details:', error);
      }
    };
    if (institute_id) {
      fetchInstituteDetails();
    } else {
      console.error('No institute ID found in session storage.');
    }
  }, [institute_id]);

  const handleChange = (e, index, field) => {
    const { value } = e.target;
    if (field === 'description') {
      setFormData(prevData => ({
        ...prevData,
        [field]: value
      }));
    } else {
      const newTableRows = [...formData.tableRows];
      newTableRows[index][field] = value;
      setFormData(prevData => ({
        ...prevData,
        tableRows: newTableRows
      }));
    }
  };

  const handleAddRow = () => {
    setFormData(prevData => ({
      ...prevData,
      tableRows: [...prevData.tableRows, {
        level: '',
        qualification: '',
        duration: '',
        startDate: '',
        professionalSkills: '',
        examFee: '',
        onlineApplyNow: '',
      }]
    }));
  };

  const handleRemoveRow = (index) => {
    const newTableRows = formData.tableRows.filter((_, i) => i !== index);
    setFormData(prevData => ({
      ...prevData,
      tableRows: newTableRows
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (const key in formData) {
      if (Array.isArray(formData[key])) {
        formDataToSend.append(key, JSON.stringify(formData[key]));
      } else if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    }

    if (institute_id) {
      formDataToSend.append('institute_id', institute_id);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'No Institute ID',
        text: 'Institute ID is missing. Please try again.',
        showConfirmButton: true,
      });
      return;
    }

    fetch('https://skillbackend.educationesupport.xyz/professionalExams/create', {
      method: 'POST',
      body: formDataToSend,
    })
    .then((response) => {
      if (response.status === 400) {
        return response.text().then((message) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Oops...',
            text: message,
            showConfirmButton: true,
          });
        });
      } else if (response.ok) {
        return response.text().then((data) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Professional Exam Added Successfully',
            text: 'Your professional exam has been added successfully.',
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            navigate('/manprofexams');
          });
        });
      } else {
        throw new Error('Network response was not ok.');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        showConfirmButton: true,
      });
    });
  };

  return (
    <main className='institute-create-pe'>
      <InstituteDashboard />
      <div className="head-content-text">
        <h4>ADD PROFESSIONAL EXAMS</h4>
      </div>
      <div className="pe_container">
        <form onSubmit={handleSubmit}>
          <div className="pe-new">
            <div className="pe-formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Drop Logo</span>
                <input type="file" accept="image/*" id="file-input" name="logo" onChange={(e) => setFormData({ ...formData, logo: e.target.files[0] })} />
              </label>
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-clipboard-list"></i>
              <input
                type="text"
                id="examName"
                name="examName"
                value={formData.examName}
                onChange={(e) => setFormData({ ...formData, examName: e.target.value })}
                placeholder="Exam Name"
              />
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-tag"></i>
              <input
                type="text"
                id="shortName"
                name="shortName"
                value={formData.shortName}
                onChange={(e) => setFormData({ ...formData, shortName: e.target.value })}
                placeholder="Short Name"
              />
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-info-circle"></i>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={(e) => handleChange(e, null, 'description')}
                placeholder="Description"
                ></textarea>
            </div>
            
            {/* Table for examination structure */}
            <div className="pe-formgroup-table">
                <h4 className="es-title">Education Structure</h4>
                <table>
                    <thead>
                    <tr>
                        <th>Level</th>
                        <th>Qualification</th>
                        <th>Duration</th>
                        <th>Start Date</th>
                        <th>Professional Skills</th>
                        <th>Exam Fee</th>
                        <th>Online Apply Now (Web)</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    
                    <tbody>
                    {formData.tableRows.map((row, index) => (
                        <tr key={index}>
                        <td><input type="text" value={row.level} onChange={(e) => handleChange(e, index, 'level')} /></td>
                        <td><input type="text" value={row.qualification} onChange={(e) => handleChange(e, index, 'qualification')} /></td>
                        <td><input type="text" value={row.duration} onChange={(e) => handleChange(e, index, 'duration')} /></td>
                        <td><input type="date" value={row.startDate} onChange={(e) => handleChange(e, index, 'startDate')} /></td>
                        <td><input type="text" value={row.professionalSkills} onChange={(e) => handleChange(e, index, 'professionalSkills')} /></td>
                        <td><input type="text" value={row.examFee} onChange={(e) => handleChange(e, index, 'examFee')} /></td>
                        <td><input type="text" value={row.onlineApplyNow} onChange={(e) => handleChange(e, index, 'onlineApplyNow')} /></td>
                        <td><button type="button" className="remove-row-btn" onClick={() => handleRemoveRow(index)}>Remove</button></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <button type="button" className="add-row-btn" onClick={handleAddRow}>Add Row</button>
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-building"></i>
              <input
                type="text"
                id="university"
                name="university"
                value={formData.university}
                onChange={handleChange}
                placeholder="Offered By (University / Institute Name)"
              />
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input
                type="text"
                id="instituteAddress"
                name="instituteAddress"
                value={formData.instituteAddress}
                onChange={(e) => setFormData({ ...formData, instituteAddress: e.target.value })}
                placeholder="Address"
              />
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-phone"></i>
              <input
                type="text"
                id="institutePhone"
                name="institutePhone"
                value={formData.institutePhone}
                onChange={(e) => setFormData({ ...formData, institutePhone: e.target.value })}
                placeholder="Contact No"
              />
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                id="instituteEmail"
                name="instituteEmail"
                value={formData.instituteEmail}
                onChange={(e) => setFormData({ ...formData, instituteEmail: e.target.value })}
                placeholder="Email"
              />
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-globe"></i>
              <input
                type="text"
                id="instituteWebsite"
                name="instituteWebsite"
                value={formData.instituteWebsite}
                onChange={(e) => setFormData({ ...formData, instituteWebsite: e.target.value })}
                placeholder="Website"
              />
            </div>
            <button type="submit" className="submit-btn">CREATE</button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default CreateProfExams;
