import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard'
import '../../assets/css/admin/allcourses.css'

const DeleteBankStdLoans = () => {
    const [bankStdLoan, setbankStdLoan] = useState([]);

    useEffect(() => {
        fetchDeletedbankStdLoan();
    }, []);

    const fetchDeletedbankStdLoan = async () => {
        try {
            const response = await fetch('https://skillbackend.educationesupport.xyz/bankStdLoan/deleted');
            const data = await response.json();
            setbankStdLoan(data);
        } catch (error) {
            console.error('Error fetching deleted Student Loans:', error);
        }
    };

    const handleRestore = async (bankStdLoanId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/bankStdLoan/restore/${bankStdLoanId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setbankStdLoan(bankStdLoan.filter(bankStdLoan => bankStdLoan.id !== bankStdLoanId));
                window.alert('Student Loan Restored');
            } else {
                console.error('Failed to restore Student Loan');
            }
        } catch (error) {
            console.error('Error restoring Student Loan:', error);
        }
    };

    const handlePermanentDelete = async (bankStdLoanId) => {
        try {
            const response = await fetch(`https://skillbackend.educationesupport.xyz/stdLoans/delete/permanent/${bankStdLoanId}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setbankStdLoan(bankStdLoan.filter(bankStdLoan => bankStdLoan.id !== bankStdLoanId));
                window.alert('Student Loan delete permanently');
            } else {
                console.error('Failed to delete Student Loan permanently');
            }
        } catch (error) {
            console.error('Error deleting Student Loan permanently:', error);
        }
    };

    return (

        <div>

            <AdminDashboard />

            <div className='AllViews-admin-course'>

                <div className="search-icon-admin admin-course-search">

                    <h1>DELETE BANK STUDENT LOANS</h1>
                    <form >
                        <input type="text" name="" id="" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                   
                </div>

                <div className="scroller-box">
                    <div className="table-course">
                    <table>
                            <thead>
                                <tr>
                                <th>Bank Name</th>
                                    <th>Student Loan Name </th>
                                    {/* <th>University Name</th> */}
                                    <th>Contact No.</th>
                                    <th>Email</th>
                                    <th>Location</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {bankStdLoan.map((bankStdLoan) => (
                                    <tr key={bankStdLoan.id}>
                                        <td>{bankStdLoan.bankName}</td>
                                        <td>{bankStdLoan.stdLoanName}</td>
                                        {/* <td>{bankStdLoan.university}</td> */}
                                        <td>{bankStdLoan.phone}</td>
                                        <td>{bankStdLoan.email}</td>
                                        <td>{bankStdLoan.location}</td>
                                        <td>
                                            <a href="#!" id='view-website'>WEBSITE</a>
                                        </td>
                                        <td id='action-buttons'>
                                            <a href="#!" id='restore-details' onClick={() => handleRestore(bankStdLoan.id)}>RESTORE</a>
                                            <a href="#!" id='del-details' onClick={() => handlePermanentDelete(bankStdLoan.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>



        </div>

    )

}

export default DeleteBankStdLoans