import React, { useEffect, useRef, useState } from 'react'
import '../assets/css/institute-register.css'
import intlTelInput from 'intl-tel-input';
import utilsScript from 'intl-tel-input/build/js/utils';
import 'intl-tel-input/build/css/intlTelInput.css'; // Assuming CSS import is necessary
import Swal from 'sweetalert2'
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import axios from 'axios';
import uniqid from 'uniqid';
import CountryDropdown from 'country-dropdown-with-flags-for-react';




const ForeignJob = () => {


  const [country, countrySet] = useState('')

  const [formData1, setFormData] = useState({
    id: "",
    aname: "",
    ano: "",
    aaddr: "",
    contact: "",
    email: "",
    jobtit: "",
    fType: "",
    msalry: "",
    avacn: "",
    otfc: "",
    mca: "",
    reqq: "",
    pdate: "",
    cdate: "",
    img: "",
    approve: "false",
    deleted: "false"
  })

  const handleSubmit = (e) => {
    e.preventDefault();

    const formdata = new FormData();

    formdata.append('id', uniqid());
    formdata.append('aname', formData1.aname);
    formdata.append('ano', formData1.ano);
    formdata.append('aaddr', formData1.aaddr);
    formdata.append('contact', formData1.contact);
    formdata.append('email', formData1.email);
    formdata.append('jobtit', formData1.jobtit);
    formdata.append('fType', formData1.fType);
    formdata.append('msalry', formData1.msalry);
    formdata.append('avacn', formData1.avacn);
    formdata.append('otfc', formData1.otfc);
    formdata.append('mca', formData1.mca);
    formdata.append('reqq', formData1.reqq);
    formdata.append('pdate', formData1.pdate);
    formdata.append('cdate', formData1.cdate);
    formdata.append('img', formData1.img);
    formdata.append('country', country);
    formdata.append('approve', formData1.approve);
    formdata.append('deleted', formData1.deleted);


    axios.post('https://skillbackend.educationesupport.xyz/fjobs/register', formdata).then((result) => {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Successfully registered",
        text: "After approval we will notify you via email",
        showConfirmButton: false,
        timer: 2500,
        customClass: {
          title: 'custom-font', // Apply custom font to the title
          content: 'custom-font' // Apply custom font to the text
        }
      }).then(() => {
        window.location.href = '/';
      });
    }).catch((err) => {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        customClass: {
          title: 'custom-font', // Apply custom font to the title
          content: 'custom-font' // Apply custom font to the text
        }
      });
    })




  }

  const handleChange = (e) => {

    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.name === 'img' ? e.target.files[0] : e.target.value
    }));


  };

  const [options, setOptions] = useState([])

  const getAll = () => {
    axios.get('https://skillbackend.educationesupport.xyz/fjobcate/getAll').then((response) => {
      console.log(response.data);
      setOptions(response.data);
    }).catch((err) => {});
  }

  useEffect(() => {
    getAll()

  }, [])




  return (
    <div>
      <div className='instituteregister'>
        <NavigationBar />

        <div className="head-content-text">
          <h4>Foreign Job Register</h4>
        </div>

        <div className="form-content">

          <form onSubmit={handleSubmit}>

            <div className="form-group-1">
              <i class="fa-solid fa-building-columns"></i>
              <input type="text" placeholder='Agency Name' name='aname' id='name' onChange={handleChange} />
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-building-columns"></i>
              <input type="text" placeholder='Register No' name='ano' id='ano' onChange={handleChange} />
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-address-book"></i>
              <input type="address" placeholder='Agency Address' name='aaddr' id='address' onChange={handleChange} />
            </div>

            <div className="form-group-1" id='form-con'>
              <i class="fa-solid fa-phone"></i>
              <input type="tel"
                id="mobile_code"
                defaultValue=""
                name='contact'
                placeholder="Agency Phone Number" onChange={handleChange} />
            </div>


            <div className="form-group-1">
              <i class="fa-solid fa-envelope"></i>
              <input type="Email" placeholder='Agency Email' name='email' id='Email' onChange={handleChange} />
            </div>


            <div className="form-group-1">
              <i class="fas fa-flag"></i>
              <span id="span-form">
                <CountryDropdown name="inscountry" className='CountryDropdown' handleChange={e => countrySet(e.target.value)}></CountryDropdown>
              </span>
            </div>


            <div className="form-group-1">
              <i class="fa-solid fa-registered"></i>
              <input type="text" placeholder='Job Title' name='jobtit' id='name' onChange={handleChange} />
            </div>

            <div className="form-group-1">
              <i className="fas fa-university"></i>
              <select id="universityType" name="fType" onChange={handleChange}>
                <option value="" disabled selected >Job Category</option>
                {options.map((option) => {
                  return <option value={option.name} style={{color:"black"}}>{option.name}</option>
                })}
              </select>
            </div>


            <div className="form-group-1">
              <i class="fa-solid fa-registered"></i>
              <input type="text" placeholder='Minimum salary' name='msalry' id='name' onChange={handleChange} />
            </div>


            <div className="form-group-1">
              <i class="fa-solid fa-registered"></i>
              <input type="text" placeholder='Available vacancies' name='avacn' id='name' onChange={handleChange} />
            </div>

            <div className="form-group-1 textarea">
              <textarea name="otfc" id="" placeholder='Enter Other facilities ' onChange={handleChange} ></textarea>
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-registered"></i>
              <input type="text" placeholder='maximum chargeable Amount' name='mca' id='name' onChange={handleChange} />
            </div>


            <div className="form-group-1">
              <label for="file-input" class="drop-container">
                <span class="" style={{ color: 'black', textTransform: 'uppercase', fontSize: "0.9rem", fontWeight: "bold" }}>Post Date</span>
                <input type="date" placeholder="Select Date" name="pdate" id="name" onChange={handleChange} />
              </label>
            </div>


            <div className="form-group-1">
              <label for="file-input" class="drop-container">
                <span class="" style={{ color: 'black', textTransform: 'uppercase', fontSize: "0.9rem", fontWeight: "bold" }}>Close Date</span>
                <input type="date" placeholder="Select Date" name="cdate" id="name" onChange={handleChange} />
              </label>
            </div>


            <div className="form-group-1">
              <label for="file-input" class="drop-container">
                <span class="drop-title">Drop the Advertisement Banner</span>
                <input type="file" accept="image/*" required="" id="file-input" name='img' onChange={handleChange} />
              </label>
            </div>

            <div className="form-group-1 textarea">
              <textarea name="reqq" id="" placeholder='Enter the required qulification ' onChange={handleChange} ></textarea>
            </div>


            <div className="form-group-1">
              <input type="submit" name='submit' value="Register" />
            </div>

          </form>

        </div>

      </div>
      <Footer />
    </div>
  )
}

export default ForeignJob