import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import SecondNav from '../components/SecondNav';
import { useParams } from 'react-router';
import axios from 'axios';
import ApplyForm from '../components/ApplyNow';
import '../assets/css/Local.css'
import Swal from 'sweetalert2';

const ViewFjobOne = () => {


  const { id } = useParams();
  const [Job, setLJob] = useState('');

  const [message, setMessage] = useState({
    name: '',
    email: '',
    contact: '',
    addr: '',
    passno: '',
    mess: ''
  })


  const getData = () => {
    axios.get(`https://skillbackend.educationesupport.xyz/fjobs/getone/${id}`).then((response) => {
      console.log(response);
      setLJob(response.data[0]);
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    getData();
  }, [id]);


  if (!Job) {
    return <p>Loading...</p>;
  }




  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessage({
      ...message,
      [name]: value
    })
  }

  const handleEmail = (e) => {

    e.preventDefault();

    const data = {
      name: message.name,
      email: message.email,
      contact: message.contact,
      addr: message.addr,
      passno: message.passno,
      mess: message.mess,
      aemail: Job.email
    }


    axios.post('https://skillbackend.educationesupport.xyz/common/sendapplyf', data).then((response) => {
      
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Message Sent",
        text: "Your information has been sent to the agency, they will contact you shortly",
        showConfirmButton: false,
        timer: 3500,

    }).then(() => {
        window.location.href = '/';
    });
    }).catch((err) => {
      console.log(err);
    })




    console.log(message);


  };



  return (
    <div>
      <SecondNav />

      <div className="content-local-job">

        <div className="local-job-view-1">

          <div className="image-in-local">
            {<img src={`https://skillbackend.educationesupport.xyz/Upload/images/${Job.img}`} alt='' width={550} />}
          </div>

          <div className="text-local-all">

            <div className="local-row01">

              <div>

                <p id='test-head' ><i class="fa-solid fa-at"></i>{Job.aname}</p>

                <div className="text-marge">
                  <p id='test-head-post'>Posted by {Job.aname} </p>
                  <p id='test-head-post'> {Job.cdate}</p>
                </div>

                <div className="test-loacl-details">

                  <div className="contect-wrap-1-local">

                    <div className="contect-1-local">
                      <p id='text-seen'>Agency name</p>
                      <p id="send-marge-local">{Job.aname}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>address</p>
                      <p id="send-marge-local">{Job.aaddr}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Contact </p>
                      <p id="send-marge-local">{Job.aaddr}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Email</p>
                      <p id="send-marge-local">{Job.email}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Close Date</p>
                      <p id="send-marge-local">{Job.cdate}</p>
                    </div>
                  </div>

                  <div className="contect-wrap-1-local">

                    <div className="contect-1-local">
                      <p id='text-seen'>Job TItle</p>
                      <p id="send-marge-local">{Job.jobtit}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Job catogrey</p>
                      <p id="send-marge-local">{Job.fType}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Minimum Salary</p>
                      <p id="send-marge-local">{Job.msalry}</p>
                    </div>


                  </div>

                  <div className="contect-wrap-1-local">

                    <div className="contect-1-local">
                      <p id='text-seen'>Country</p>
                      <p id="send-marge-local">{Job.country}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>available vacancies</p>
                      <p id="send-marge-local">{Job.avacn}</p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>maximum charge amount</p>
                      <p id="send-marge-local">{Job.mca}</p>
                    </div>

                    {/* <div className="contect-1-local">
                      <p id='text-seen'>Company Web</p>
                      <p id="send-marge-local"></p>
                    </div>

                    <div className="contect-1-local">
                      <p id='text-seen'>Company Address</p>
                      <p id="send-marge-local"></p>
                    </div> */}
                  </div>

                </div>



                <div className="contect-wrap-1-local">

                  <div className="contect-1-local">
                    <p id='text-seen'>Other facility</p>
                    <p id="send-marge-local">{Job.otfc}</p>
                  </div>
                </div>

                <div className="contect-wrap-1-local">

                  <div className="contect-1-local">
                    <p id='text-seen'>required qualification</p>
                    <p id="send-marge-local">{Job.reqq}</p>
                  </div>
                </div>


              </div>

            </div>

          </div>
        </div>


        <div className="applyConLocal1">

          <div className="text-lo">
            <h2>Apply Now</h2>
            <hr id="local-app" />
          </div>

          {/* <button id="button-up" onClick={handleEmail}>
            SEND DETAILS
          </button> */}

          <form onSubmit={handleEmail}>

            <div className="first-fjob-1">
              <input type="text" placeholder='NAME' name="name" onChange={handleChange} />
              <input type="email" placeholder='EMAIL' name="email" onChange={handleChange} />
              <input type="text" placeholder='CONTACT' name="contact" onChange={handleChange} />
            </div>

            <div className="first-fjob-1">
              <input type="text" placeholder='ADDRESS' name="addr" onChange={handleChange} />
              <input type="text" placeholder='PASSPOART NO' name="passno" onChange={handleChange} />
            </div>

            <div className="first-fjob-1">
              <textarea placeholder="MESSAGE" name="mess" id="" onChange={handleChange}></textarea>
            </div>

            <input type="submit" id="button-up" value="Apply Now" style={{ textTransform: 'uppercase', padding: "10px" }} />


          </form>


        </div>

      </div>



      <Footer />
    </div>
  )
}

export default ViewFjobOne